export enum RSVP {
  NO = "NO",
  YES = "YES",
  NOT_RESPONDED = "NOT_RESPONDED",
}

export enum DieteryRequirements {
  VEGAN = "Vegan",
  VEGITARIAN = "Vegitarian",
  GLUTEN_FREE = "Gluten Free",
}

export interface reservation {
  address: string;
  duration: number;
}

export interface Attendee {
  firstName: string;
  lastName: string;
  email: string;
  response: RSVP;
  id: string;
  dietaryRequirements: string;
  address?: any;
  updatedOn?: Date;
  createdDate?: Date;
  reservation?: reservation;
  isChild?: boolean;
  dessert?: string;
  main?: string;
  starter?: string;
}

export interface Group {
  attendees: string[];
  id: string;
  attendeeDetails: Attendee[];
  updatedOn?: Date;
  createdDate?: Date;
  groupName: string;
  email?: string;
  needsTransport?: boolean;
}
