import React from "react";
import { Col, Row } from "react-bootstrap";
import { PillBorderImg, RelativePhotoContainer } from "../shared/demo/Photos";
import ourStoryPhoto from "../assets/personalPhotos/12.jpg";
import cottonDiagonal from "../assets/plants/nude-cotton-diagonal.png";
import cotton from "../assets/plants/nude-cotton.png";
import sunflower from "../assets/plants/sunflower.png";
import selfie2 from "../assets/personalPhotos/dresses.jpeg";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { Agenda } from "../shared/static/agenda";
import { useIsMobile } from "../shared/hooks/responsive";
import { off } from "process";

export const OurStory: React.FC<any> = () => {
  const isMobile = useIsMobile();
  return (
    <Row className="align-items-center my-4 justify-content-around bg-light">
      <Col
        xs={10}
        lg={6}
        style={{
          padding: "2rem",
          maxWidth: "500px",
          maxHeight: "600px",
          marginTop: isMobile ? "-80px" : "0px",
        }}
      >
        <RelativePhotoContainer src={ourStoryPhoto} />
      </Col>
      <Col sm={12} md={8} lg={6}>
        <p className="ff-1 pt-4 fz-3 text-center">Our Story</p>
        <p>
          How did we start out? Well, there was a lot of wine, a lot of good
          food, and a lot of laughter; and not much has changed now! We met in
          October 2019 and we spent the first few months of our relationship
          hungover in various cities, until COVID stopped us in our tracks and
          then we were just hungover in the garden.
        </p>
        <p>
          We spent 5 months living in a tiny room, learning absolutely
          everything about each other, which is one of the reasons we’re getting
          married, we know too much! After numerous lockdowns and normality
          returning, living apart made us realise how much we wanted to move in
          together and so we bought a house on a hill, and we’ve spent the last
          two years out of breath walking up it.
        </p>
        <p>
          The last four years we’ve created so many happy memories, and hiked
          more miles than any person needs to, but we’re looking forward to what
          the next chapter holds and we’re so excited to celebrate!
        </p>
      </Col>
    </Row>
  );
};

export const Timeline: React.FC = () => (
  <Row className="m-0 p-0">
    {Agenda.map((a, i) => {
      const isStart = i === 0 || i % 2 === 0;
      const defaultClass = "d-flex justify-content-center";

      if (isStart) {
        return (
          <Row className="m-0 p-0" key={`timeline-row-${i}`}>
            <Col
              md={6}
              xs={12}
              className={`${defaultClass} py-4 border-end text-end align-items-center`}
            >
              <AgendaDetails
                position="end"
                {...a}
                content={
                  <>
                    {a.content.map((s) => (
                      <p key={`timeline-${i}`}>{s}</p>
                    ))}
                  </>
                }
              />
            </Col>
            {a.img && (
              <Col md={6} xs={12}>
                <div className="d-flex justify-content-center">
                  <RelativePhotoContainer
                    src={a.img}
                    style={{ maxWidth: "200px" }}
                  />
                </div>
              </Col>
            )}
          </Row>
        );
      }

      return (
        <Row
          className="p-0"
          style={{ marginLeft: -1 }}
          key={`timeline-row-${i}`}
        >
          {a.img && (
            <Col md={6} xs={12}>
              <div className="d-flex justify-content-center d-none d-md-flex">
                <RelativePhotoContainer
                  src={a.img}
                  style={{ maxWidth: "200px" }}
                />
              </div>
            </Col>
          )}
          <Col
            md={{ span: 6, offset: 6 }}
            xs={{ span: 12 }}
            className={`${defaultClass} py-4 border-start align-items-center`}
          >
            <AgendaDetails
              {...a}
              content={
                <>
                  {a.content.map((s) => (
                    <p key={`timeline-${i}`}>{s}</p>
                  ))}
                </>
              }
            />
          </Col>
        </Row>
      );
    })}
  </Row>
);

export const TimelineLegacy: React.FC<any> = () => (
  <Row>
    <Col
      md={6}
      xs={12}
      className="border-end py-4 text-end d-flex justify-content-center align-items-center"
    >
      <AgendaDetails
        position="end"
        heading="Arrival"
        time="11:00am"
        content={
          <>
            <p className="text-end">Welcome to our Wedding!</p>
            <p className="text-end">
              Settle in, relax and enjoy all the amazing surroundings of the
              venue. It is situated in a peacful reteat, what to do when they
              arrive
            </p>
            <p className="text-end">
              Some details on what to expect when they arrive or where parking
              is located etc.
            </p>
          </>
        }
      />
    </Col>
    <Col
      md={6}
      xs={12}
      className="d-none d-md-flex border-start py-4 d-flex justify-content-center align-items-center"
    >
      <div style={{ maxWidth: "300px" }}>
        <RelativePhotoContainer src={cotton} />
      </div>
    </Col>
    <Col
      md={6}
      xs={12}
      className="d-none d-md-flex border-end py-4 text-end d-flex justify-content-center align-items-center"
    >
      <PillBorderImg
        position="top"
        maxWidth="250px"
        photo={selfie2}
        borderImg={sunflower}
      />
    </Col>
    <Col
      md={6}
      xs={12}
      className="border-start py-4 d-flex justify-content-center align-items-center"
    >
      <AgendaDetails
        heading="Ceremony"
        time="12:00pm"
        content={
          <>
            <p>
              Join us in our wedding ceromony as we join as a married couple
              exchanging vows with our cherished family and friends, or some
              cute text
            </p>
            <p>
              Maybe some details on etiquete, as in refrain from photos or
              vidoes if you already have a photographer or if children start
              acting up silently excuse yourself.
            </p>
          </>
        }
      />
    </Col>
    <Col
      md={6}
      xs={12}
      className="border-end py-4 text-end d-flex justify-content-center align-items-center"
    >
      <AgendaDetails
        heading="Welcome Reception"
        time="12:45pm"
        position="end"
        content={
          <>
            <p>
              Gather tofether for some time together on the grounds while the
              rest of the event is set up. Durign this time we will be taking
              group photos...
            </p>
          </>
        }
      />
    </Col>
    <Col
      xs={12}
      md={6}
      className="border-start py-4 d-flex justify-content-center align-items-center"
    >
      <div style={{ maxWidth: "300px" }}>
        <RelativePhotoContainer src={cottonDiagonal} />
      </div>
    </Col>
    <Col
      md={6}
      xs={12}
      className="d-none d-md-flex border-end py-4 text-end d-flex justify-content-center align-items-center"
    >
      Image TBD
      {/* <PillBorderImg
        position="top"
        maxWidth="250px"
        photo={selfie2}
        borderImg={sunflower}
      /> */}
    </Col>
    <Col
      md={6}
      xs={12}
      className="border-start py-4 d-flex justify-content-center align-items-center"
    >
      <AgendaDetails
        heading="Welcome Breakfast"
        time="2:15pm"
        content={
          <>
            <p>TBD</p>
          </>
        }
      />
    </Col>
    <Col
      md={6}
      xs={12}
      className="border-end py-4 text-end d-flex justify-content-center align-items-center"
    >
      <AgendaDetails
        heading="Speeches"
        time="3:45pm"
        position="end"
        content={
          <>
            <p>TBD</p>
          </>
        }
      />
    </Col>
    <Col
      xs={12}
      md={6}
      className="border-start py-4 d-flex justify-content-center align-items-center"
    >
      <div style={{ maxWidth: "300px" }}>
        Image TBD
        {/* <RelativePhotoContainer src={cottonDiagonal} /> */}
      </div>
    </Col>
    <Col
      md={6}
      xs={12}
      className="d-none d-md-flex border-end py-4 text-end d-flex justify-content-center align-items-center"
    >
      Image TBD
      {/* <PillBorderImg
        position="top"
        maxWidth="250px"
        photo={selfie2}
        borderImg={sunflower}
      /> */}
    </Col>
    <Col
      md={6}
      xs={12}
      className="border-start py-4 d-flex justify-content-center align-items-center"
    >
      <AgendaDetails
        heading="Reception"
        time="6:00pm"
        content={
          <>
            <p>TBD</p>
          </>
        }
      />
    </Col>
    <Col
      md={6}
      xs={12}
      className="border-end py-4 text-end d-flex justify-content-center align-items-center"
    >
      <AgendaDetails
        heading="First Dancec & Cutting of Cake"
        time="7:30pm"
        position="end"
        content={
          <>
            <p>TBD</p>
          </>
        }
      />
    </Col>
    <Col
      xs={12}
      md={6}
      className="border-start py-4 d-flex justify-content-center align-items-center"
    >
      <div style={{ maxWidth: "300px" }}>
        Image TBD
        {/* <RelativePhotoContainer src={cottonDiagonal} /> */}
      </div>
    </Col>
    <Col
      md={6}
      xs={12}
      className="d-none d-md-flex border-end py-4 text-end d-flex justify-content-center align-items-center"
    >
      Image TBD
      {/* <PillBorderImg
        position="top"
        maxWidth="250px"
        photo={selfie2}
        borderImg={sunflower}
      /> */}
    </Col>
    <Col
      md={6}
      xs={12}
      className="border-start py-4 d-flex justify-content-center align-items-center"
    >
      <AgendaDetails
        heading="Food"
        time="8:00pm"
        content={
          <>
            <p>TBD</p>
          </>
        }
      />
    </Col>
  </Row>
);

interface AgendaDetailsProps {
  // date: string;
  heading: string;
  content: React.ReactElement<any>;
  position?: "start" | "end";
  time: string;
}

const AgendaDetails: React.FC<AgendaDetailsProps> = ({
  // date,
  heading,
  content,
  position = "start",
  time,
}) => (
  <>
    <Row className={`w-100 my-2 flex-column align-items-${position}`}>
      {/* <small>{date}</small> */}
      <h4 className="fw-bold mt-2">{heading}</h4>
      {time && <p>{time}</p>}
      {content}
    </Row>
  </>
);

export const Venue: React.FC<any> = () => {
  const AnyReactComponent = ({ text }: { text: any }) => <div>{text}</div>;

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  return (
    <>
      <Row className="bg-primary">
        <Col
          md={6}
          className="d-flex pe-4 py-4 flex-column justify-content-center  align-items-center"
        >
          <p className="ff-1 fz-3 text-end w-100 text-light">Gellifawr</p>
          <p className="text-end text-light">
            Situated in the Pembrokeshire National Park within 12 acres of
            landscaped gardens, ponds and woodland, this idyllic wedding
            village, with beautiful outdoor spaces and lovingly renovated
            buildings, was the perfect choice for us.
          </p>
          <div className="w-100 d-flex justify-content-end">
            <a
              href="https://goo.gl/maps/iym7uM1VFtBX47Nr8"
              className="text-end text-light"
            >
              Pontfaen Rd, Fishguard SA65 9TX, United Kingdom
            </a>
          </div>
        </Col>
        <Col md={6} className="p-0">
          <MapWrapper />
        </Col>
      </Row>
    </>
  );
};

const MapComp = (props: any) => {
  return (
    // @ts-ignore
    <Map
      google={props.google}
      zoom={15}
      containerStyle={{
        minWidth: "100px",
        minHeight: "500px",
        position: "relative",
      }}
      mapTypeControl={false}
      fullscreenControl={false}
      streetViewControl={false}
      zoomControl={true}
      initialCenter={{ lat: 51.975234650598296, lng: -4.822757159370872 }}
    >
      {/* @ts-ignore */}
      <Marker position={{ lat: 51.975, lng: -4.822757159370873 }} />
    </Map>
  );
};

export const MapWrapper = GoogleApiWrapper({
  apiKey: "AIzaSyCnNJbGkW6yiETkR14dpTT3lJysUTEtThE",
})(MapComp);
