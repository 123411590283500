import { useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { adminLogin } from "../shared/client";

const BEARER_TOKEN_KEY = "wedding_token";
const REFRESH_TOKEN_KEY = "wedding_refresh_token";

export const getTokens = (): {
  bearer: string | null;
  refresh: string | null;
} => {
  return {
    bearer: localStorage.getItem(BEARER_TOKEN_KEY),
    refresh: localStorage.getItem(REFRESH_TOKEN_KEY),
  };
};

export const setTokens = (tokens: { token?: string; refresh?: string }) => {
  tokens.refresh && localStorage.setItem(REFRESH_TOKEN_KEY, tokens.refresh);
  tokens.token && localStorage.setItem(BEARER_TOKEN_KEY, tokens.token);
};

export const Login = () => {
  const { handleSubmit, register } = useForm();
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const onSubmit = (data: any) => {
    setLoading(true);
    setError(undefined);
    adminLogin(data)
      .then(() => {
        navigate("/admin");
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container style={{ maxWidth: "400px" }}>
      <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.FloatingLabel label="Email">
                <Form.Control
                  type="text"
                  placeholder="Email"
                  {...register("email")}
                />
              </Form.FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-3">
              <Form.FloatingLabel label="Password">
                <Form.Control
                  type="password"
                  placeholder="Passowrd"
                  {...register("password")}
                />
              </Form.FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <div className="d-flex flex-column justify-content-center">
          <Button type="submit" className="px-4">
            {loading ? (
              <Spinner variant="light" size="sm" animation="border" />
            ) : (
              "Sign In"
            )}
          </Button>
          {error && <p className="text-center text-danger mt-4">{error}</p>}
        </div>
      </Form>
    </Container>
  );
};
