import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../shared/store/hooks";
import { getGroup, updateAttendeeValues } from "../shared/store/publicSlice";
import { Attendee, RSVP } from "../model";
import { Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ChildMenu, Menu } from "../shared/static/menu";
import { SubmitButton } from "../shared/components/Simple";
import { publicRSVP, resendDetails } from "../shared/client";
import { useToastContext } from "../shared/components/Toast";
import { AlertTriangle } from "react-feather";

export const FoodChoices = () => {
  const group = useAppSelector(getGroup);
  const [selectedAttendee, setSelectedAttendee] = useState<Attendee>();

  return (
    <Container>
      {group && (
        <Tabs
          fill
          defaultActiveKey={group.attendees[0]}
          id="uncontrolled-tab-example"
        >
          {group.attendeeDetails.map((attendee) => (
            <Tab
              eventKey={attendee.id}
              title={`${attendee.firstName} ${attendee.lastName}`}
            >
              {attendee.main ? (
                <>
                  <p className="ff-5 fz-2 py-2 text-center mt-4 text-primary">
                    I ddechrau/ To start:
                  </p>
                  <hr />
                  {attendee.starter}
                  <p className="ff-5 fz-2 mt-4 py-2 text-center text-primary">
                    Prif gwrs/ Main course:
                  </p>
                  <hr />
                  {attendee.main} with seasonal greens
                  <p className="ff-5 fz-2 mt-4 py-2 text-center text-primary">
                    Pwdin / Dessert:
                  </p>
                  <hr />
                  {attendee.dessert}
                </>
              ) : attendee.response === RSVP.YES ? (
                <FoodChoice
                  attendeeId={attendee.id}
                  isChild={!!attendee.isChild}
                  groupEmail={group.email!}
                />
              ) : (
                <p className="text-center pt-4">This person is not attending</p>
              )}
            </Tab>
          ))}
        </Tabs>
      )}
    </Container>
  );
};

const FoodChoice: React.FC<{
  attendeeId: string;
  isChild: boolean;
  groupEmail: string;
}> = ({ attendeeId, isChild, groupEmail }) => {
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [useChildsMenu, setUseChildsMenu] = useState<boolean>(true);
  const { addToast } = useToastContext();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
    clearErrors,
  } = useForm({});

  const watchMain = watch("main");

  useEffect(() => {
    if (watchMain !== Menu.main[2]) {
      clearErrors("dietaryRequirements");
    }
  }, [watchMain]);

  const setChildMeals = (isUsingChildsMenu: boolean) => {
    setUseChildsMenu(isUsingChildsMenu);
    if (isUsingChildsMenu) {
      setValue("starter", ChildMenu.starter[0]);
      setValue("main", ChildMenu.main[0]);
      setValue("dessert", ChildMenu.dessert[0]);
    } else {
      setValue("starter", null);
      setValue("main", null);
      setValue("dessert", null);
    }
  };

  useEffect(() => {
    if (isChild) {
      setChildMeals(true);
    }
  }, []);

  const onSubmit = () => {
    setSubmitLoading(true);
    publicRSVP(attendeeId, { ...getValues(), email: groupEmail })
      .then((res) => {
        addToast({
          variant: "success",
          body: "Menu options sent successfully",
        });

        sendDetails();

        dispatch(updateAttendeeValues(res.data.result!));
      })
      .catch((err: any) => {
        console.log(err.response?.data?.error);
        addToast({
          variant: "danger",
          body:
            JSON.stringify(err.response?.data?.error) ||
            "Unable to send response",
        });
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const sendDetails = () => {
    resendDetails(attendeeId)
      .then(() => {})
      .catch((err) => {
        console.log("unable to send email");
      });
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {isChild && (
          <Row>
            <p className="mt-4 text-center">
              Children are able to select from either a set children's menu or
              have child sized portions from the adult menu.
            </p>
            <div className="d-flex justify-content-center">
              <Form.Check
                type="radio"
                label={"Use child menu"}
                checked={useChildsMenu}
                onChange={() => setChildMeals(true)}
                className="me-4"
              />
              <Form.Check
                type="radio"
                label={"Use adult menu"}
                checked={!useChildsMenu}
                onChange={() => setChildMeals(false)}
              />
            </div>
          </Row>
        )}
        {isChild && useChildsMenu && (
          <Row className="mt-4 text-center">
            <p className="ff-5 fz-2 py-2 text-center ">STARTERS:</p>
            <p>{ChildMenu.starter[0]}</p>
            <p className="ff-5 fz-2 mt-4 py-2 text-center">MAINS:</p>
            <p>{ChildMenu.main[0]}</p>
            <p className="ff-5 fz-2 mt-4 py-2 text-center">DESSERTS:</p>
            <p>{ChildMenu.dessert[0]}</p>
            <Col xs={12}>
              <Form.Group className="my-4 pt-4">
                <Form.FloatingLabel label="Additional dietary requirements">
                  <Form.Control
                    type="Dietary Requirements"
                    placeholder="Email"
                    {...register("dietaryRequirements", {
                      required: false,
                    })}
                  />
                </Form.FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
        )}
        {((isChild && !useChildsMenu) || !isChild) && (
          <>
            <Row className="my-4">
              <Col xs={12}>
                <p className="ff-5 fz-2 py-2 text-center text-primary">
                  I ddechrau/ To start:
                </p>
                <hr />
                {Menu.starter.map((s, i) => (
                  <Form.Check
                    disabled={submitLoading}
                    key={`starter-${i}`}
                    type="radio"
                    label={s}
                    value={s}
                    {...register("starter")}
                  />
                ))}
              </Col>
              <Col xs={12}>
                <p className="ff-5 fz-2 mt-4 py-2 text-center text-primary">
                  Prif gwrs/ Main course:
                </p>
                <hr />
                <p className="text-center mt-2">
                  All served with seasonal greens
                </p>
                {Menu.main.map((s, i) => (
                  <Form.Check
                    disabled={submitLoading}
                    key={`main-${i}`}
                    type="radio"
                    label={s}
                    value={s}
                    {...register("main")}
                  />
                ))}
              </Col>
              <Col xs={12}>
                <p className="ff-5 fz-2 mt-4 py-2 text-center text-primary">
                  Pwdin / Dessert:
                </p>
                <hr />
                {Menu.dessert.map((s, i) => (
                  <Form.Check
                    disabled={submitLoading}
                    key={`dessert-${i}`}
                    type="radio"
                    label={s}
                    value={s}
                    {...register("dessert")}
                  />
                ))}
              </Col>
              <Col xs={12}>
                <Form.Group className="my-4 pt-4">
                  <p className="text-start">
                    Dishes are adjusted to suit dietary requirements. Please
                    state (e.g. GF, V, VG):
                  </p>
                  <Form.FloatingLabel label="Additional dietary requirements">
                    <Form.Control
                      disabled={submitLoading}
                      type="Dietary Requirements"
                      placeholder="Email"
                      {...register("dietaryRequirements", {
                        required:
                          getValues("main") !== Menu.main[2] ? false : true,
                      })}
                    />
                    {getValues("main") === Menu.main[2] &&
                      !getValues("dietaryRequirements") && (
                        <Form.Text className="text-danger">
                          <AlertTriangle /> Please provide dietary requirements
                          as you have selected this option for your main.
                        </Form.Text>
                      )}
                  </Form.FloatingLabel>
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
        <div className="d-flex my-4 pt-4 justify-items-center">
          <SubmitButton
            disabled={Object.keys(errors).length > 0}
            loading={submitLoading}
          />
        </div>
      </Form>
    </Container>
  );
};
