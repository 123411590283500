import { Button, Col, Container, Row } from "react-bootstrap";
import { LoremIpsumOne } from "../shared/stock";
import { RSVP, SearchName } from "./rsvp";
import { BrandOne } from "../shared/demo/Brands";
import { SpotifySearch } from "../shared/components/Spotify";
import { OurStory, Timeline, Venue } from "./Text";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import purpleSeperatorFlower from "../assets/plants/purple-seperator-flower.png";
import { RelativePhotoContainer } from "../shared/demo/Photos";
import { useAppSelector } from "../shared/store/hooks";
import { getAttendees, getGroup } from "../shared/store/publicSlice";
import GuestResponse from "../shared/components/GuestInfo";
import { GalleryWedding } from "./Gallery";

export enum SCROLLTO {
  RSVP = "rsvpContainer",
  SONG = "songRequestContainer",
  AGENDA = "agendaContainer",
  VENUE = "venueContainer",
  STORY = "ourStoryContainer",
  GALLERY = "galleryContainer",
}

export const scrollTo = (
  id: SCROLLTO,
  location: "center" | "start" = "start"
) => {
  if (!!document.getElementById(id)) {
    window.scrollBy(
      0,
      document.getElementById(id)!.getBoundingClientRect().top - 100
    );
  }
};

export const Home = () => {
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const attendees = useAppSelector(getAttendees);
  const group = useAppSelector(getGroup);

  useEffect(() => {
    if (hash) {
      // @ts-ignore
      setTimeout(() => scrollTo(hash.replace("#", "")), 100);
    }
  }, [pathname, hash]);

  return (
    <Container
      fluid
      className="m-0 p-0"
      style={{
        overflowX: "hidden",
      }}
    >
      <BrandOne />

      <Container>
        <Row className="justify-content-center m-large">
          <Col xs="auto" onClick={() => navigate(`/#${SCROLLTO.RSVP}`)}>
            Responses
          </Col>
          <Col xs="auto" onClick={() => navigate(`/#${SCROLLTO.AGENDA}`)}>
            The Agenda
          </Col>
          <Col xs="auto" onClick={() => navigate(`/#${SCROLLTO.VENUE}`)}>
            The Venue
          </Col>
          <Col xs="auto" onClick={() => navigate(`/#${SCROLLTO.SONG}`)}>
            Song Request
          </Col>
          <Col xs="auto" onClick={() => navigate(`/#${SCROLLTO.GALLERY}`)}>
            Gallery
          </Col>
        </Row>
      </Container>

      <Container>
        <span id={SCROLLTO.STORY}>
          <OurStory />
        </span>
        <div className="m-large">
          <p className="ff-1 fz-3 text-center mb-4" id={SCROLLTO.AGENDA}>
            The Agenda
          </p>
          <Timeline />
        </div>
      </Container>

      <Container>
        <p className="ff-1 fz-3 text-center mb-4" id={SCROLLTO.GALLERY}>
          Gallery
        </p>
        <GalleryWedding />
      </Container>

      <Container>
        <p className="ff-1 fz-3 text-center" id={SCROLLTO.VENUE}>
          Venue
        </p>
        <Venue />
      </Container>

      <Container id={SCROLLTO.RSVP} className="my-4 px-4">
        <p className="ff-1 fz-3 text-center">Responses</p>

        {group?.needsTransport && (
          <p className="text-center">
            You have requests for transport to and from the venue. Please follow
            up with Nia and Scott for the details.
          </p>
        )}
        <Row className="justify-content-center">
          {attendees?.map((a) => (
            <GuestResponse key={a.id} attendee={a} groupEmail={group?.email} />
          ))}
        </Row>
      </Container>

      <Container>
        <Row className="align-items-center flex-column mb-4 pb-4">
          <div className="d-block mx-auto mb-4" style={{ width: "300px" }}>
            <RelativePhotoContainer src={purpleSeperatorFlower} />
          </div>

          <p className="ff-1 fz-3 text-center" id={SCROLLTO.SONG}>
            Song Request
          </p>

          <p className="text-center" style={{ maxWidth: "800px" }}>
            We want our wedding to be inclusive and a celebration for all. Let’s
            make this night extra special by sharing in music we all love.
          </p>

          <SpotifySearch />

          <div className="m-large"></div>
        </Row>
      </Container>
    </Container>
  );
};
