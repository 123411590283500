import React from "react";
import { Container, Row } from "react-bootstrap";
import { S3ImageUploader } from "../shared/components/S3ImageUploader";
import S3Images from "./S3Images";

const ImageUpload: React.FC = () => {
  return (
    <Container>
      <Row>
        <S3ImageUploader />
      </Row>
      <Row>
        <S3Images />
      </Row>
    </Container>
  );
};

export default ImageUpload;
