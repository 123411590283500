import React, { useEffect } from "react";
import { Button, Col, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Group, RSVP } from "../../model";
import {
  adminDeleteAttendee,
  adminDeleteGroup,
  adminSendGroupInvite,
  adminUpdatePassword,
  adminViewPassword,
  useAdminUpdateGroupAndAttendees,
} from "../../shared/client";
import { SubmitButton } from "../../shared/components/Simple";
import { useToastContext } from "../../shared/components/Toast";
import { capitalizeFirstLetter } from "../../shared/functions";
import { useIsMobile } from "../../shared/hooks/responsive";
import { generatePwd } from "../pwdGenerator";
import { RefreshCw } from "react-feather";
import { Trash } from "react-feather";
import { Eye } from "react-feather";
import {
  updateGroup,
  deleteGroup as delGroupState,
} from "../../shared/store/adminSlice";
import GuestResponse from "../../shared/components/GuestInfo";

interface EditGroupModalProps {
  handleClose: () => void;
  show: boolean;
  group: Group;
}

export const EditGroup: React.FC<EditGroupModalProps> = ({
  show,
  handleClose,
  group,
}) => {
  const { register, handleSubmit, reset, control, setValue } = useForm({});
  const { addToast } = useToastContext();
  const dispatch = useDispatch();
  const {
    request,
    error,
    group: groupRes,
    loading,
  } = useAdminUpdateGroupAndAttendees();
  const isMobile = useIsMobile();
  const [guestCount, setGuestCount] = React.useState<number>(3);
  const [inviteSending, setInviteSending] = React.useState<boolean>(false);

  const resetForms = (g: Group) => {
    reset();
    setValue("group.groupName", g.groupName);
    setValue("group.email", g.email);
    setGuestCount(g.attendees.length);
    for (let i = 0; i < g.attendees.length; i++) {
      setValue(`attendees.${i}.firstName`, g.attendeeDetails[i].firstName);
      setValue(`attendees.${i}.lastName`, g.attendeeDetails[i].lastName);
      setValue(`attendees.${i}.isChild`, g.attendeeDetails[i].isChild);
    }
  };

  useEffect(() => {
    if (group) {
      resetForms(group);
    }
  }, [group]);

  useEffect(() => {
    if (!loading && !error && groupRes) {
      reset();
      addToast({ body: "Updated group successfully", variant: "success" });
      handleClose();
      dispatch(updateGroup(groupRes));
    }

    if (!loading && error) {
      addToast({
        body: "Error adding guests, check console for details",
        variant: "danger",
      });
      addToast({ body: error, variant: "danger" });
    }
  }, [groupRes, loading, error]);

  const {} = useFieldArray({
    control,
    name: "guest",
  });

  const generatePassword = () => {
    const newPwd = generatePwd();
    adminUpdatePassword(group.id, newPwd)
      .then(() => {
        addToast({
          body: "Password updated successfully",
          variant: "success",
        });
        setValue("password", newPwd);
      })
      .catch((e) => {
        addToast({
          body: "Error updating password, check console for details",
          variant: "danger",
        });
        console.log(e);
      });
  };

  const showPassword = () => {
    adminViewPassword(group.id)
      .then((res) => {
        setValue("password", res.data.result);
      })
      .catch((e) => {
        addToast({
          body: "Error getting password, check console for details",
          variant: "danger",
        });
        console.log(e);
      });
  };

  const onSubmit = (data: any) => {
    const attendees = data.attendees
      .filter(
        (g: any) =>
          g.firstName !== "" && g.lastName !== "" && g.firstName && g.lastName
      )
      .map((g: any, i: number) => {
        return {
          ...g,
          firstName: capitalizeFirstLetter(g.firstName),
          lastName: capitalizeFirstLetter(g.lastName),
          id:
            group.attendeeDetails.find(
              (a) => a.firstName === g.firstName && a.lastName === g.lastName
            )?.id || group.attendees[i],
        };
      });

    const { reservationDuration, ...reservationLess } = data.group;

    if (data.group.password === "" || data.group.password === undefined) {
      const { password, ...passwordLess } = reservationLess;
      request(passwordLess, attendees, group.id);
    } else {
      request(data.group, attendees, group.id);
    }
  };

  const deleteGuest = (id: string) => {
    adminDeleteAttendee(group.id, id)
      .then(() => {
        resetForms({
          ...group,
          attendeeDetails: group.attendeeDetails.filter((a) => a.id !== id),
          attendees: group.attendees.filter((a) => a !== id),
        });
        addToast({
          body: "Guest deleted successfully",
          variant: "success",
        });
      })
      .catch((e) => {
        addToast({
          body: "Error deleting guest, check console for details",
          variant: "danger",
        });
        console.log(e);
      });
  };

  const deleteGroup = () => {
    adminDeleteGroup(group.id)
      .then(() => {
        addToast({
          body: "Group deleted successfully",
          variant: "success",
        });
        dispatch(delGroupState(group.id));
        handleClose();
      })
      .catch((e) => {
        addToast({
          body: "Error deleting group, check console for details",
          variant: "danger",
        });
        console.log(e);
      });
  };

  const sendInvite = () => {
    if (window.confirm("Are you sure you want to send an email?")) {
      setInviteSending(true);
      adminSendGroupInvite(group.id)
        .then(() => {
          addToast({
            body: "Invite sent successfully",
            variant: "success",
          });
        })
        .catch((e) => {
          addToast({
            body: "Error sending invite, check console for details",
            variant: "danger",
          });
          console.log(e);
        })
        .finally(() => setInviteSending(false));
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl" scrollable centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="view">
          <Tab eventKey="view" title="Details">
            <Row className="mt-4">
              <p>Quick Actions:</p>
              <Row>
                <div>
                  <SubmitButton
                    text="Send Invite"
                    loading={inviteSending}
                    onclick={sendInvite}
                    skipType={true}
                  />
                </div>
              </Row>
            </Row>
            <hr />
            <Row className="mt-4">
              <Col xs={12} sm={12}>
                <Row>
                  <p>Group: {group.groupName}</p>

                  <Col xs={12} sm={6} md={4}>
                    <p>Email: {group.email}</p>
                  </Col>
                  <Col xs={12} sm={6} md={4}>
                    <p>
                      {group.needsTransport
                        ? "Transport Needed"
                        : "Trasport Not Needed  "}
                    </p>
                  </Col>
                </Row>
              </Col>
              <hr />
              <Col xs={12} sm={12}>
                <Row>
                  {group.attendeeDetails.map((g, i) => {
                    return <GuestResponse attendee={g} />;
                  })}
                </Row>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="exit" title="Edit form">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="mt-4">
                <Col xs={12} sm={6}>
                  <Form.Group className="m-1">
                    <Form.FloatingLabel label="Group Name">
                      <Form.Control
                        type="text"
                        placeholder="Group Name"
                        {...register(`group.groupName`, {})}
                        autoComplete="off"
                      />
                    </Form.FloatingLabel>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <div className="d-flex align-items-center">
                    <Form.Group className="m-1 flex-grow-1">
                      <Form.FloatingLabel label="Group Password">
                        <Form.Control
                          type="text"
                          disabled
                          placeholder="Group Password"
                          {...register(`password`, {})}
                          autoComplete="off"
                        />
                      </Form.FloatingLabel>
                    </Form.Group>
                    <RefreshCw
                      size={20}
                      className="mx-4"
                      onClick={generatePassword}
                    />
                    <Eye size={20} className="mx-4" onClick={showPassword} />
                  </div>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group className="m-1">
                    <Form.FloatingLabel label="Email">
                      <Form.Control
                        type="text"
                        placeholder="Email"
                        {...register(`group.email`, {})}
                        autoComplete="off"
                      />
                    </Form.FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              {new Array(guestCount).fill(0).map((_, i) => {
                return (
                  <Row className="mb-2">
                    <Col md={5} xs={12}>
                      <div className="d-flex align-items-center">
                        <Trash
                          size={20}
                          className="me-2 text-danger"
                          onClick={() => deleteGuest(group.attendees[i])}
                        />
                        <Form.Group className="m-1 flex-grow-1">
                          <Form.FloatingLabel label="First Name">
                            <Form.Control
                              type="text"
                              placeholder="First Name"
                              {...register(`attendees.${i}.firstName`, {})}
                              autoComplete="off"
                            />
                          </Form.FloatingLabel>
                        </Form.Group>
                      </div>
                    </Col>
                    <Col md={5} xs={12}>
                      <Form.Group className="m-1">
                        <Form.FloatingLabel label="Last Name">
                          <Form.Control
                            type="text"
                            placeholder="Last Name"
                            {...register(`attendees.${i}.lastName`, {})}
                            autoComplete="off"
                          />
                        </Form.FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col md={2} xs={12}>
                      <Form.Group className="mt-3">
                        <Form.Check
                          type="checkbox"
                          {...register(`attendees.${i}.isChild`, {})}
                          label="Child"
                        />
                      </Form.Group>
                    </Col>
                    {i !== guestCount - 1 && isMobile && (
                      <hr className="mt-4" />
                    )}
                  </Row>
                );
              })}
              <hr />
              <div className="d-flex justify-content-between">
                <span>
                  <Button
                    variant="outline-primary"
                    onClick={() => setGuestCount(guestCount + 5)}
                    className="me-4"
                  >
                    Add more inputs
                  </Button>
                  <SubmitButton></SubmitButton>
                </span>
                <Button
                  variant="danger"
                  onClick={deleteGroup}
                  className="ms-4 flex-end"
                >
                  Delete Group
                </Button>
              </div>
            </Form>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};
