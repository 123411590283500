import agendaOne from "../../assets/plants/agenda-1.png";
import agendaTwo from "../../assets/plants/agenda-2.png";
import agendaThree from "../../assets/plants/agenda-3.png";
import agendaFour from "../../assets/plants/agenda-4.png";
import agendaFive from "../../assets/plants/agenda-5.png";
import agendaSix from "../../assets/plants/agenda-6.png";
import agendaSeven from "../../assets/plants/agenda-7.png";

interface IAgendaItem {
  position?: "start" | "end";
  heading: string;
  time: string;
  content: string[];
  img?: any;
}

export const Agenda: IAgendaItem[] = [
  {
    content: [],
    time: "11:00am",
    heading: "Church wedding ceremony followed by photographs",
    // img: agendaOne,
  },
  {
    content: [],
    time: "1:30pm",
    heading: "Guest arrival, drinks reception & canapes",
    // img: agendaSix,
  },
  {
    content: [""],
    time: "3:00pm",
    heading: "Wedding Breakfast",
    // img: agendaTwo,
  },
  {
    content: [],
    time: "4:45pm",
    heading: "Speeches",
    // img: agendaFour,
  },
  {
    content: [],
    time: "6:30pm",
    heading: "Evening guests arrive",
    // img: agendaFive,
  },
  {
    content: [],
    time: "7:30pm",
    heading: "Evening food",
    // img: agendaSeven,
  },
  {
    content: [],
    time: "8:30pm",
    heading: "Cake cut, firstdance & pary!!",
    // img: agendaThree,
  },
  {
    content: [],
    time: "12:00am",
    heading: "Carriages",
    // img: agendaThree,
  },
];
