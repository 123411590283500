import { useNavigate } from "react-router";
import { Group, RSVP } from "../model";

export const useNavigationBoundaries = (group?: Group | null) => {
  const navigate = useNavigate();

  const shouldNavigateNotResponded = () => {
    if (
      group?.attendeeDetails.find(
        (a) => a.response === RSVP.NOT_RESPONDED || !a.response
      ) ||
      group?.attendeeDetails.every((a) => a.response === RSVP.NO)
    ) {
      return true;
    }
    return false;
  };

  const shouldNavigateIntoSite = () => {
    if (
      group?.attendeeDetails &&
      !group.attendeeDetails.find(
        (a) =>
          a.response === RSVP.NOT_RESPONDED ||
          !a.response ||
          group?.attendeeDetails.every((a) => a.response === RSVP.NO)
      )
    ) {
      navigate("/");
    }
  };

  return { shouldNavigateNotResponded, shouldNavigateIntoSite };
};
