import { useEffect, useState } from "react";

export type Breakpoint = "lg" | "sm" | "xs" | "md";

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
};

export const useResponsiveBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>("lg");

  useEffect(() => {
    const handleResize = () => {
      switch (true) {
        case window.innerWidth < 576:
          setBreakpoint("xs");
          break;
        case window.innerWidth < 768:
          setBreakpoint("sm");
          break;
        case window.innerWidth < 992:
          setBreakpoint("md");
          break;
        default:
          setBreakpoint("lg");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return breakpoint;
};
