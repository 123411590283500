import axios from "axios";
import qs from "qs";
import { useState } from "react";

const CLIENT_ID = process.env.REACT_APP_SPOTIFY_ID;
const CLIENT_SECRET = process.env.REACT_APP_SPOTIFY_SECRET;

const TOKEN_KEY = "wedding_spotify_token";
const TOKEN_TYPE = "wedding_spotify_token_type";

export interface SpotifySearchResult {
  tracks: {
    href: string;
    limit: number;
    next: string; // href for next call
    offset: number;
    previous: string; //href for previous page
    total: number;
    items: SongItem[];
  };
}

export interface SpotifyImage {
  url: string;
  height: number;
  width: number;
}

export interface SongItem {
  album: {
    album_type: string;
    total_tracks: number;
    available_markets: string[];
    href: string;
    id: string;
    images: SpotifyImage[];
    name: string;
    release_date: string;
    type: string;
    uri: string;
    label: string;
    artists: [
      {
        external_urls: {
          spotify: "string";
        };
        href: "string";
        id: "string";
        name: "string";
        type: "artist";
        uri: "string";
      }
    ];
  };
  artists: [
    {
      external_urls: {
        spotify: string;
      };
      href: string;
      id: string;
      images: SpotifyImage[];
      name: string;
    }
  ];
  available_markets: string[];
  explicit: boolean;
  external_urls: {
    spotify: string;
  };
  href: string;
  id: string;
  is_playable: boolean;
  name: string;
  preview_url: string;
  type: string;
  uri: string;
}

export const getTokens = (): {
  token: string | null;
  type: string | null;
} => {
  return {
    token: localStorage.getItem(TOKEN_KEY),
    type: localStorage.getItem(TOKEN_TYPE),
  };
};

export const setTokens = (tokens: {
  access_token?: string;
  token_type?: string;
}) => {
  tokens.access_token && localStorage.setItem(TOKEN_KEY, tokens.access_token);
  tokens.token_type && localStorage.setItem(TOKEN_TYPE, tokens.token_type);
};

const axiosSpotifyInstance = axios.create();

// Request interceptor for API calls
axiosSpotifyInstance.interceptors.request.use(
  async (config) => {
    if (config.url !== "https://accounts.spotify.com/api/token" && getTokens().type) {
      // @ts-ignore
      config.headers = {
        Authorization: `${getTokens().type} ${getTokens().token}`,
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosSpotifyInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const data = qs.stringify({
        grant_type: "client_credentials",
        client_secret: CLIENT_SECRET,
        client_id: CLIENT_ID,
      });
      await axiosSpotifyInstance
        .post("https://accounts.spotify.com/api/token", data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          setTokens(res.data);
        })
        .catch((err) => {
          console.log("Error connecting to spotify: ", err);
        });
      return axiosSpotifyInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

export const useSpotifySearchForSong = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<SongItem[] | undefined | null>();
  const [error, setError] = useState<any>();

  const request = (song: string) => {
    setLoading(true);
    setError(null);
    axiosSpotifyInstance
      .get<SpotifySearchResult>(
        `https://api.spotify.com/v1/search?type=track&q=${encodeURI(
          song
        )}&limit=5`
      )
      .then((res) => {
        console.log(res);
        setResult(res.data.tracks.items);
      })
      .catch((err) => {
        setError("Something went wrong connecting to spotify");
        console.log(err);
        setResult(null);
      })
      .finally(() => setLoading(false));
  };

  return { loading, error, request, result };
};
