import React, { useEffect } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { RSVP } from "../../model";
import { useAdminCreateGroupAndAttendees } from "../../shared/client";
import { SubmitButton } from "../../shared/components/Simple";
import { useToastContext } from "../../shared/components/Toast";
import { capitalizeFirstLetter } from "../../shared/functions";
import { addGroup } from "../../shared/store/adminSlice";
import { useIsMobile } from "../../shared/hooks/responsive";
import { generatePwd } from "../pwdGenerator";
import { RefreshCw } from "react-feather";

interface CreateGroupModalProps {
  handleClose: () => void;
  show: boolean;
}

export const CreateGroup: React.FC<CreateGroupModalProps> = ({
  show,
  handleClose,
}) => {
  const { register, handleSubmit, reset, control, setValue } = useForm({});
  const { addToast } = useToastContext();
  const dispatch = useDispatch();
  const { request, error, group, loading } = useAdminCreateGroupAndAttendees();
  const isMobile = useIsMobile();

  useEffect(() => {
    generatePassword();
  }, [show]);

  useEffect(() => {
    if (!loading && !error && group) {
      // Dispatch new group to list
      reset();
      setGuestCount(3);
      addToast({ body: "Created group successfully", variant: "success" });
      dispatch(addGroup(group));
    }

    if (!loading && error) {
      addToast({
        body: "Error adding guests, check console for details",
        variant: "danger",
      });
      addToast({ body: error, variant: "danger" });
    }
  }, [group, loading, error]);

  const {} = useFieldArray({
    control,
    name: "guest",
  });

  const generatePassword = () => {
    setValue("group.password", generatePwd());
  };

  const onSubmit = (data: any) => {
    const attendees = data.attendees
      .filter((g: any) => g.firstName !== "" && g.lastName !== "")
      .map((g: any) => {
        return {
          ...g,
          firstName: capitalizeFirstLetter(g.firstName),
          lastName: capitalizeFirstLetter(g.lastName),
          response: RSVP.NOT_RESPONDED,
        };
      });

    request(data.group, attendees);
  };

  const [guestCount, setGuestCount] = React.useState<number>(3);

  return (
    <Modal show={show} onHide={handleClose} size="lg" scrollable centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12} sm={6}>
              <Form.Group className="m-1">
                <Form.FloatingLabel label="Group Name">
                  <Form.Control
                    type="text"
                    placeholder="Group Name"
                    {...register(`group.groupName`, {})}
                    autoComplete="off"
                  />
                </Form.FloatingLabel>
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <div className="d-flex align-items-center">
                <Form.Group className="m-1 flex-grow-1">
                  <Form.FloatingLabel label="Group Password">
                    <Form.Control
                      type="text"
                      placeholder="Group Password"
                      {...register(`group.password`, {})}
                      autoComplete="off"
                    />
                  </Form.FloatingLabel>
                </Form.Group>
                <RefreshCw
                  size={20}
                  className="mx-4"
                  onClick={generatePassword}
                />
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group className="m-1">
                <Form.FloatingLabel label="Email">
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    {...register(`group.email`, {})}
                    autoComplete="off"
                  />
                </Form.FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <p className="my-3">
            Add names of people in the group. Please check mark them if they are
            a child. The default email provided above will be used to send out
            the invitation and send responses unless guests provide their own
            email.
          </p>
          {new Array(guestCount).fill(0).map((_, i) => {
            return (
              <Row className="mb-2">
                <Col md={5} xs={12}>
                  <Form.Group className="m-1">
                    <Form.FloatingLabel label="First Name">
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        {...register(`attendees.${i}.firstName`, {})}
                        autoComplete="off"
                      />
                    </Form.FloatingLabel>
                  </Form.Group>
                </Col>
                <Col md={5} xs={12}>
                  <Form.Group className="m-1">
                    <Form.FloatingLabel label="Last Name">
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        {...register(`attendees.${i}.lastName`, {})}
                        autoComplete="off"
                      />
                    </Form.FloatingLabel>
                  </Form.Group>
                </Col>
                <Col md={2} xs={12}>
                  <Form.Group className="ms-4 mt-3">
                    <Form.Check
                      type="checkbox"
                      {...register(`attendees.${i}.isChild`, {})}
                      label="Child"
                    />
                  </Form.Group>
                </Col>
                {/* <Col xs={2}>
                  <Form.Group className="m-1">
                    <Form.FloatingLabel label="Nights">
                      <Form.Control
                        type="number"
                        placeholder="Nights"
                        {...register(`guest.${i}.reservation.duration`, {})}
                        autoComplete="off"
                      />
                    </Form.FloatingLabel>
                  </Form.Group>
                </Col> */}
                {i !== guestCount - 1 && isMobile && <hr className="mt-4" />}
              </Row>
            );
          })}
          <hr />
          <div className="d-flex justify-content-center">
            <span>
              <Button
                variant="outline-primary"
                onClick={() => setGuestCount(guestCount + 5)}
                className="me-4"
              >
                Add more inputs
              </Button>
              <SubmitButton></SubmitButton>
            </span>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
