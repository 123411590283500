import { Container } from "react-bootstrap";

export const RelativePhotoContainer: React.FC<any> = ({
  src,
  className,
  children,
  style,
}) => (
  <img src={src} className={className} width="100%" style={{ ...style }}></img>
);

interface BackgroundRowProps {
  img: any;
  className?: string;
  opacity?: 25 | 50 | 75;
  bg?: string;
  children: any;
}

export const BackgroundRow: React.FC<BackgroundRowProps> = ({
  img,
  className,
  bg = "primary",
  opacity,
  children,
}) => (
  <div
    className={`${className} img-cover`}
    style={{ backgroundImage: `url(${img})` }}
  >
    {opacity && (
      <div className={`bg-${bg} bg-opacity-${opacity} p-4`}>
        <Container className="my-4">{children}</Container>
      </div>
    )}
    {!opacity && <Container className="my-4">{children}</Container>}
  </div>
);

export const VintagePhoto: React.FC<{ maxWidth?: string; children: any }> = ({
  children,
  maxWidth = "100%",
}) => (
  <div className="shadow p-4 bg-white" style={{ maxWidth }}>
    <div className="mb-4">{children}</div>
    <p className="ff-1 fw-bold text-center fs-3">04/05/2024</p>
  </div>
);

interface PillBorderImg {
  photo: any;
  borderImg?: any;
  position?: "top" | "bottom";
  maxWidth?: string;
}

export const PillBorderImg: React.FC<PillBorderImg> = ({
  photo,
  borderImg,
  position = "bottom",
  maxWidth = "100%",
}) => (
  <div style={{ position: "relative", maxWidth }}>
    <RelativePhotoContainer style={{ borderRadius: "12rem" }} src={photo} />
    {borderImg && (
      <img
        src={borderImg}
        style={{
          backgroundImage: `url(${borderImg})`,
          position: "absolute",
          bottom: position === "top" ? "40%" : "-35%",
          right: "-20%",
          width: "80%",
        }}
      />
    )}
  </div>
);
