import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Menu } from "react-feather";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Countdown } from "../shared/components/Countdown";
import { NavBrand } from "../shared/demo/Brands";
import { useIsMobile } from "../shared/hooks/responsive";
import { SCROLLTO } from "./home";
import { clearTokens } from "./authentication";

interface NavSideBarProps {
  isOpen: boolean;
  hasBeenClicked: boolean;
  handleClose: () => void;
}

export const NavSideBar: React.FC<NavSideBarProps> = ({
  isOpen,
  hasBeenClicked,
  handleClose,
}) => {
  const [useAnimation, setUseAnimation] = useState(0);
  const isMobile = useIsMobile();
  const { pathname } = useLocation();

  useEffect(() => {
    setUseAnimation(useAnimation + 1);
  }, [isOpen]);

  const MobileRoutes = () => (
    <>
      <NavLink
        className={({ isActive }) =>
          `admin-nav ${isActive ? "nav-active" : ""}`
        }
        to="/"
      >
        Home
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          `admin-nav ${isActive ? "nav-active" : ""}`
        }
        to="/rsvp"
      >
        Responses
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          `admin-nav ${isActive ? "nav-active" : ""}`
        }
        to="/agenda"
      >
        Agenda
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          `admin-nav ${isActive ? "nav-active" : ""}`
        }
        to="/venue"
      >
        Venue
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          `admin-nav ${isActive ? "nav-active" : ""}`
        }
        to="/song-request"
      >
        Song Request
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          `admin-nav ${isActive ? "nav-active" : ""}`
        }
        to="/gallery"
      >
        Gallery
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          `admin-nav ${isActive ? "nav-active" : ""}`
        }
        to="/auth"
        onClick={clearTokens}
      >
        Logout
      </NavLink>
    </>
  );

  const LgRoutes = () => (
    <>
      <NavLink className="admin-nav" to={`/#${SCROLLTO.STORY}`}>
        Our Story
      </NavLink>
      <NavLink className="admin-nav" to={`/#${SCROLLTO.RSVP}`}>
        Responses
      </NavLink>
      <NavLink className="admin-nav" to={`/#${SCROLLTO.AGENDA}`}>
        Agenda
      </NavLink>
      <NavLink className="admin-nav" to={`/#${SCROLLTO.VENUE}`}>
        Venue
      </NavLink>
      <NavLink className="admin-nav" to={`/#${SCROLLTO.SONG}`}>
        Song Request
      </NavLink>
      <NavLink className="admin-nav" to="/auth" onClick={clearTokens}>
        Logout
      </NavLink>
    </>
  );

  const AdminRoutes = () => (
    <>
      <NavLink
        className={({ isActive }) =>
          `admin-nav ${isActive ? "nav-active" : ""}`
        }
        to="/admin/rsvp"
      >
        RSVP
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          `admin-nav ${isActive ? "nav-active" : ""}`
        }
        to="/admin/guests"
      >
        Guests
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          `admin-nav ${isActive ? "nav-active" : ""}`
        }
        to="/"
      >
        Public Site
      </NavLink>
      <NavLink
        className={({ isActive }) =>
          `admin-nav ${isActive ? "nav-active" : ""}`
        }
        to="/admin/songs"
      >
        Songs
      </NavLink>
    </>
  );

  return (
    <Container
      fluid
      className={
        "mobileSideNav m-0 p-0 bg-primary " +
        (useAnimation > 1 && hasBeenClicked && (isOpen ? "open" : "closed"))
      }
      style={{
        zIndex: 900,
        width: isMobile ? "100%" : "250px",
      }}
    >
      <Row
        className="m-0 flex-column text-white"
        style={{ paddingTop: "75px" }}
      >
        {pathname.includes("/admin") && <AdminRoutes />}
        {!pathname.includes("/admin") &&
          (isMobile ? <MobileRoutes /> : <LgRoutes />)}
      </Row>
    </Container>
  );
};

interface NavbarProps {
  toggleNav: () => void;
}

export const NavBar: React.FC<NavbarProps> = ({ toggleNav }) => {
  const [showShadow, setShowShadow] = useState(false);
  const location = useLocation();

  const [isHome, setIsHome] = useState(false);
  const [isBrandInView, setIsBrandInView] = useState(true);
  const [isCountdownInView, setIsCountdownInView] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const mobileContent = document.body;

      if (mobileContent) {
        const scrollHeight = Math.abs(
          mobileContent.getBoundingClientRect().top
        );
        if (scrollHeight > 50) {
          setShowShadow(true);
        } else {
          setShowShadow(false);
        }

        if (isHome) {
          const brandImage = document.getElementById("brandImage");
          const brandCountdown = document.getElementById("brandDaysLeft");

          if (
            brandImage &&
            mobileContent &&
            brandImage.getBoundingClientRect().bottom < scrollHeight - 50
          ) {
            setIsBrandInView(false);
          } else {
            setIsBrandInView(true);
          }

          if (
            brandCountdown &&
            mobileContent &&
            brandCountdown.getBoundingClientRect().bottom < scrollHeight - 250
          ) {
            setIsCountdownInView(false);
          } else {
            setIsCountdownInView(true);
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    if (location.pathname === "/") {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location, isHome]);

  return (
    <Container
      fluid
      className={
        "py-2 d-flex align-items-center justify-content-between  bg-white " +
        (showShadow && "shadow")
      }
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        height: "60px",
      }}
    >
      <span className="d-flex align-items-center">
        <Menu size="30px" className="text-primary" onClick={toggleNav} />
        {isHome && !isBrandInView && <NavBrand />}
        {!isHome && <NavBrand />}
      </span>
      {isHome && !isCountdownInView && <Countdown small />}
      {!isHome && <Countdown small />}
    </Container>
  );
};
