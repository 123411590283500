import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { AlertCircle, AlertTriangle } from "react-feather";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { Attendee, DieteryRequirements } from "../model";
import {
  ServerResponse,
  publicRSVP,
  resendDetails,
  useGetPublicAttendeeByName,
} from "../shared/client";
import { Loading, SubmitButton } from "../shared/components/Simple";
import { useToastContext } from "../shared/components/Toast";
import { capitalizeFirstLetter } from "../shared/functions";
import { RSVP as Responses } from "../model";
import { GoldFrameNames } from "../shared/demo/Brands";
import { Invite1, Invite2 } from "../shared/demo/BorderCards";
import { useAppDispatch, useAppSelector } from "../shared/store/hooks";
import {
  getAttendees,
  getGroup,
  updateAttendeeValues,
} from "../shared/store/publicSlice";
import GuestResponse from "../shared/components/GuestInfo";
import { ChildMenu, Menu } from "../shared/static/menu";

export const RSVP: React.FC<{
  groupEmail?: string;
  attendee?: Attendee;
  onSubmitSuccess?: () => void;
}> = ({ attendee: attendeeProps, onSubmitSuccess, groupEmail }) => {
  const [attendee, setAttendee] = useState<Attendee | null | undefined>(
    attendeeProps
  );
  const [error, setError] = useState<any>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm({});
  const { addToast } = useToastContext();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [attending, setAttending] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [useChildsMenu, setUseChildsMenu] = useState<boolean>(true);

  useEffect(() => {
    if (attendee) {
      setValue("firstName", attendee.firstName);
      setValue("lastName", attendee.lastName);
      if (attendee.isChild) {
        setChildMeals(true);
      }
    } else {
      reset();
      setAttending(false);
    }
  }, [attendee]);

  const onSubmit = () => {
    const email = getValues().email || groupEmail;
    setSubmitLoading(true);
    setSubmitLoading(false);
    publicRSVP(attendee?.id, { ...getValues(), email })
      .then((res) => {
        addToast({
          variant: "success",
          body: "Response sent succesfully",
        });

        attendee?.id && sendDetails(attendee.id);

        dispatch(updateAttendeeValues(res.data.result!));
        onSubmitSuccess && onSubmitSuccess();
      })
      .catch((err: any) => {
        console.log(err.response?.data?.error);
        addToast({
          variant: "danger",
          body:
            JSON.stringify(err.response?.data?.error) ||
            "Unable to send response",
        });
      })
      .finally(() => {
        setSubmitLoading(false);
        setAttendee(null);
      });
  };

  const sendDetails = (attendeeId: string) => {
    resendDetails(attendeeId)
      .then(() => {})
      .catch((err) => {
        console.log("unable to send email");
      });
  };

  const setChildMeals = (isUsingChildsMenu: boolean) => {
    setUseChildsMenu(isUsingChildsMenu);
    if (isUsingChildsMenu) {
      setValue("starter", ChildMenu.starter[0]);
      setValue("main", ChildMenu.main[0]);
      setValue("dessert", ChildMenu.dessert[0]);
    } else {
      setValue("starter", null);
      setValue("main", null);
      setValue("dessert", null);
    }
  };

  return (
    <Container fluid className="d-flex p-0 m-0 flex-column align-items-center">
      <Container
        style={{ maxWidth: "1000px" }}
        className="p-0 m-0 d-flex shadow bg-alt-light flex-column align-items-center"
        id="rsvpInvite"
      >
        <Invite2>
          <GoldFrameNames
            style={{ marginTop: "250px", marginBottom: "60px" }}
          />
          <p>Joyfully invite,</p>
          <p style={{ fontSize: "2rem" }}>
            {attendee?.firstName} {attendee?.lastName}
          </p>
          <p>to their wedding ceremony</p>
          <div>
            <span style={{ fontSize: "2rem" }}>May |</span>
            <span style={{ fontSize: "3rem" }}> 4 </span>
            <span style={{ fontSize: "2rem" }}>| 2024</span>
          </div>
          {/* <p className="">At x o'clock</p> */}
          {attendee?.reservation?.duration && (
            <p>
              {" "}
              You also have accomation arranged for you for{" "}
              <strong>{attendee.reservation.duration} nights</strong>.
            </p>
          )}
          <Col xs={10} md={8} style={{ marginBottom: "250px" }}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="justify-content-center mt-4">
                <Col xs={12} className="mb-2 d-flex justify-content-center">
                  <Form.Check
                    inline
                    type="radio"
                    label="Attending"
                    onChangeCapture={() => setAttending(true)}
                    value={Responses.YES}
                    {...register("response", { required: true })}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Can't make it"
                    value={Responses.NO}
                    onChangeCapture={() => setAttending(false)}
                    {...register("response", { required: true })}
                  />
                </Col>
                {attending && (
                  <>
                    <p className="mt-4 text-center">
                      You can get responses sent to a new email instead of the
                      one the invite was received. Leave blank to default to the
                      origional email address.
                    </p>
                    <Col lg={6} md={6} xs={12}>
                      <Form.Group className="my-3">
                        <Form.FloatingLabel label="Email">
                          <Form.Control
                            type="text"
                            placeholder="Email"
                            {...register("email", {
                              required: false,
                              pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                            })}
                          />
                          {errors.email && errors.email.type === "pattern" && (
                            <Form.Text className="text-danger">
                              <small>Please enter a valid email</small>
                            </Form.Text>
                          )}
                        </Form.FloatingLabel>
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>
              {attending && attendee?.isChild && (
                <Row>
                  <p className="mt-4 text-center">
                    Children are able to select from either a set children's
                    menu or have child sized portions from the adult menu.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Form.Check
                      type="radio"
                      label={"Use child menu"}
                      checked={useChildsMenu}
                      onChange={() => setChildMeals(true)}
                      className="me-4"
                    />
                    <Form.Check
                      type="radio"
                      label={"Use adult menu"}
                      checked={!useChildsMenu}
                      onChange={() => setChildMeals(false)}
                    />
                  </div>
                </Row>
              )}
              {attendee?.isChild && attending && useChildsMenu && (
                <Row className="mt-4 text-center">
                  <p className="ff-5 fz-2 py-2 text-center">STARTERS:</p>
                  <p>{ChildMenu.starter[0]}</p>
                  <p className="ff-5 fz-2 mt-4 py-2 text-center">MAINS:</p>
                  <p>{ChildMenu.main[0]}</p>
                  <p className="ff-5 fz-2 mt-4 py-2 text-center">DESSERTS:</p>
                  <p>{ChildMenu.dessert[0]}</p>
                  <Col xs={12}>
                    <Form.Group className="my-4 pt-4">
                      <Form.FloatingLabel label="Additional dietary requirements">
                        <Form.Control
                          type="Dietary Requirements"
                          placeholder="Email"
                          {...register("dietaryRequirements", {
                            required: false,
                          })}
                        />
                      </Form.FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
              )}
              {attending &&
                ((attendee?.isChild && !useChildsMenu) ||
                  !attendee?.isChild) && (
                  <>
                    <Row className="my-4">
                      <Col xs={12}>
                        <p className="ff-5 fz-2 py-2 text-center">STARTERS:</p>
                        {Menu.starter.map((s, i) => (
                          <Form.Check
                            key={`starter-${i}`}
                            type="radio"
                            label={s}
                            value={s}
                            {...register("starter", {
                              required: attending,
                            })}
                          />
                        ))}
                      </Col>
                      <Col xs={12}>
                        <p className="ff-5 fz-2 mt-4 py-2 text-center">
                          MAINS:
                        </p>
                        {Menu.main.map((s, i) => (
                          <Form.Check
                            key={`main-${i}`}
                            type="radio"
                            label={s}
                            value={s}
                            {...register("main", {
                              required: attending,
                            })}
                          />
                        ))}
                      </Col>
                      <Col xs={12}>
                        <p className="ff-5 fz-2 mt-4 py-2 text-center">
                          DESSERTS:
                        </p>
                        {Menu.dessert.map((s, i) => (
                          <Form.Check
                            key={`dessert-${i}`}
                            type="radio"
                            label={s}
                            value={s}
                            {...register("dessert", {
                              required: attending,
                            })}
                          />
                        ))}
                      </Col>
                      <Col xs={12}>
                        <Form.Group className="my-4 pt-4">
                          <Form.FloatingLabel label="Additional dietary requirements">
                            <Form.Control
                              type="Dietary Requirements"
                              placeholder="Email"
                              {...register("dietaryRequirements", {
                                required: false,
                              })}
                            />
                          </Form.FloatingLabel>
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
              <div className="d-flex my-4 pt-4 justify-items-center">
                <SubmitButton
                  disabled={Object.keys(errors).length > 0}
                  loading={submitLoading}
                />
              </div>
            </Form>
          </Col>
        </Invite2>
      </Container>
      {/* )} */}
    </Container>
  );
};

interface AlreadyRespondedProps {
  email: string;
}
const AlreadyResponded: React.FC<AlreadyRespondedProps> = ({ email }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const toastContext = useToastContext();

  const sendDetails = () => {
    setLoading(true);
    resendDetails(email)
      .then(() => {
        toastContext.addToast({
          variant: "success",
          body: "Succesfully sent email",
        });
      })
      .catch((err) => {
        toastContext.addToast({
          variant: "danger",
          body: err,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Container style={{ maxWidth: "500px" }}>
      <div className="d-flex justify-content-center">
        <AlertCircle size="40px" className="text-danger" />
      </div>
      <p className="text-center my-4">
        This person has already responded to the invitation. If you would like
        to be reminded of your choices then please click the button below.
      </p>
      <p className="text-center">
        Be aware that if you have used the same email to respond for mutlipe
        people you will get multiple emails per guest.
      </p>
      <div className="d-flex py-4 justify-content-center">
        <SubmitButton
          loading={loading}
          variant="outline-primary"
          text={`Resend details to ${email}`}
          onclick={sendDetails}
        />
      </div>
    </Container>
  );
};

interface SearchNameProps {
  setAttendee: (s: Attendee | null) => void;
  setError: (e: any) => void;
  setRespondedEmail: (e: string | null) => void;
}

export const SearchName: React.FC<SearchNameProps> = ({
  setAttendee,
  setError,
  setRespondedEmail,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();
  const {
    request,
    attendee,
    loading,
    error: requestError,
  } = useGetPublicAttendeeByName();

  const onSubmit = (data: any) => {
    setError(null);
    setAttendee(null);
    setRespondedEmail(null);

    request({
      firstName: capitalizeFirstLetter(data.firstName),
      lastName: capitalizeFirstLetter(data.lastName),
    });
  };

  useEffect(() => {
    if (attendee?.id) {
      setAttendee(attendee);
    }
  }, [attendee]);

  useEffect(() => {
    console.log(requestError, typeof requestError);
    if (typeof requestError === "string" && requestError.indexOf("@") > 0) {
      setRespondedEmail(requestError);
    } else {
      setError(requestError);
    }
  }, [requestError]);

  return (
    <Container>
      {/* <p className="ff-1 fz-3 text-center">Respond</p> */}
      <p className="text-center mx-auto mb-4" style={{ maxWidth: "500px" }}>
        Use the below form to search for your name and respond to your wedding
        invitation.
      </p>
      <Form
        className="d-flex flex-column align-items-center"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <Col lg={6} md={6} xs={12}>
            <Form.Group className="mb-3">
              <Form.FloatingLabel label="First Name">
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  {...register("firstName", { required: true })}
                  autoComplete="off"
                />
              </Form.FloatingLabel>
            </Form.Group>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <Form.Group className="mb-3">
              <Form.FloatingLabel label="Last Name">
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  {...register("lastName", { required: true })}
                  autoComplete="off"
                />
              </Form.FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <SubmitButton
              disabled={Object.keys(errors).length > 0}
              loading={loading}
            />
          </Col>
          <Col xs={6}>
            <Button
              variant="outline-primary"
              onClick={() => {
                setAttendee(null);
                reset();
              }}
            >
              Clear
            </Button>
          </Col>
        </Row>
      </Form>
      <small
        className="text-center d-block mx-auto mt-4 pt-4"
        style={{ maxWidth: "500px" }}
      >
        Please note that any responses will not be able to be changed through
        the website and you will need to reach out directly to the organisers.
      </small>
    </Container>
  );
};

export const AlreadyRespondedPage: React.FC = () => {
  const attendees = useAppSelector(getAttendees);
  const group = useAppSelector(getGroup);

  return (
    <Container className="mt-4 p-2">
      <Row className="justify-content-center">
        {attendees?.map((a) => (
          <GuestResponse key={a.id} attendee={a} groupEmail={group?.email} />
        ))}
      </Row>
    </Container>
  );
};
