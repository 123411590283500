import { Container } from "react-bootstrap";
import goldBorder from "../../assets/borders/gold-frame.png";
import plant1 from "../../assets/plants/small-left-plant.png";
import bottomWreath from "../../assets/plants/bottom-wreath.png";
import { Countdown } from "../components/Countdown";
import { useIsMobile } from "../hooks/responsive";

export const BrandOne = () => {
  const isMobile = useIsMobile();
  return (
    <>
      <Container
        style={{ minWidth: "100%", marginTop: isMobile ? "-150px" : "-100px" }}
      >
        <div
          id="brandImage"
          className="img-contain d-flex justify-content-center align-items-center"
          style={{
            backgroundImage: `url(${bottomWreath})`,
            minHeight: "400px",
          }}
        >
          <h1 className="ff-1 text-center mx-4" style={{ fontSize: "3rem" }}>
            N{" "}
            <span
              style={{
                fontSize: "2rem",
                lineHeight: "3rem",
                verticalAlign: "top",
              }}
            >
              &
            </span>{" "}
            S
          </h1>
        </div>
      </Container>
      <Container>
        <h1 className="ff-1 text-center mt-4">Nia & Scott</h1>
      </Container>
      <Container className="mt-4">
        <span id="brandDaysLeft">
          <Countdown />
        </span>
      </Container>
    </>
  );
};

export const NavBrand = () => (
  <span className="ff-1 text-center pt-2 ps-4" style={{ fontSize: "1.5rem" }}>
    N | S
  </span>
);

export const BrandTwo = () => (
  <Container
    style={{
      minWidth: "350px",
      minHeight: "380px",
      maxWidth: "550px",
      maxHeight: "580px",
    }}
  >
    <div
      className="img-contain img-bottom-left "
      style={{ backgroundImage: `url(${plant1})` }}
    >
      <div style={{ marginLeft: "-3rem" }}>
        <h1 className="ff-1 text-center" style={{ fontSize: "3rem" }}>
          Nia
        </h1>
        <h3 className="ff-1 text-center" style={{ marginLeft: "5rem" }}>
          and
        </h3>
        <h1
          className="ff-1 text-center"
          style={{ fontSize: "3rem", marginLeft: "15rem" }}
        >
          Scott
        </h1>
      </div>
    </div>
  </Container>
);

export const GoldFrameNames: React.FC<any> = ({ style }) => (
  <Container style={{ width: "350px", height: "250px", ...style }}>
    <div
      className="img-contain d-flex flex-column justify-content-center"
      style={{ backgroundImage: `url(${goldBorder})` }}
    >
      <h1 className="ff-1 text-center" style={{ fontSize: "3rem" }}>
        Nia
      </h1>
      <h3 className="ff-1 text-center">and</h3>
      <h1 className="ff-1 text-center" style={{ fontSize: "3rem" }}>
        Scott
      </h1>
    </div>
  </Container>
);
