const Tile: React.FC<{ day: any; small?: boolean }> = ({ day, small }) => {
  const padding = small ? "py-1 px-2" : "p-2";
  return (
    <div
      className={"border mx-1 rounded d-block " + padding}
      style={{ width: "fit-content" }}
    >
      {day}
    </div>
  );
};

export const Countdown: React.FC<{ small?: boolean }> = ({ small }) => {
  const daysLeft = getDaysLeft();
  const lengthDays = daysLeft.toString().length;

  const daysArr = () => {
    switch (lengthDays) {
      case 1:
        return [0, 0, daysLeft.toString()[0]];
      case 2:
        return [0, daysLeft.toString()[0], daysLeft.toString()[1]];
      case 3:
        return [
          daysLeft.toString()[0],
          daysLeft.toString()[1],
          daysLeft.toString()[2],
        ];
      default:
        return [0, 0, 0];
    }
  };

  if (small)
    return (
      <div className="d-flex align-items-center justify-content-center text-light">
        <Tile day={daysArr()[0]} small={small} />
        <Tile day={daysArr()[1]} small={small} />
        <Tile day={daysArr()[2]} small={small} />

        <span className=" text-center"> days left</span>
      </div>
    );

  return (
    <div className="d-flex align-items-center justify-content-center text-light">
      <Tile day={daysArr()[0]} small={small} />
      <Tile day={daysArr()[1]} small={small} />
      <Tile day={daysArr()[2]} small={small} />
      <span className="ff-1 ms-3 fz-2 text-center"> days left</span>
    </div>
  );
};

export const getDaysLeft = () =>
  Math.round(
    (new Date("2024-09-14").getTime() - new Date().getTime()) /
      1000 /
      60 /
      60 /
      24
  );
