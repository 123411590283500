import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { NavSideBar, NavBar as CustomNavBar } from "./navigation";
import { getGroup, setGroup } from "../shared/store/publicSlice";
import { useAppDispatch, useAppSelector } from "../shared/store/hooks";
import { usePublicGetDefaultGroup } from "../shared/client";
import { Loading } from "../shared/components/Simple";
import { Card, Container } from "react-bootstrap";
import { RSVP } from "../model";
import { clearTokens } from "./authentication";
import { useIsMobile } from "../shared/hooks/responsive";
import { HomeNavWrapper } from "./HomeNavWrapper";

const Public: React.FC<any> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const location = useLocation();
  const [hasBeenClicked, setHasBeenClicked] = React.useState(false);
  const group = useAppSelector(getGroup);
  const {
    error,
    group: resGroup,
    loading,
    request,
  } = usePublicGetDefaultGroup();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  useEffect(() => {
    setIsOpen(false);
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (
      !group &&
      !resGroup &&
      !loading &&
      !error &&
      window.location.pathname !== "/auth"
    ) {
      request();
    }
    if (resGroup) {
      dispatch(setGroup(resGroup));
      if (
        resGroup.attendeeDetails.find(
          (a) => a.response === RSVP.NOT_RESPONDED || !a.response
        ) ||
        resGroup.attendeeDetails.filter((a) => a.response === RSVP.NO)
          .length === resGroup.attendeeDetails.length
      ) {
        navigate("/rsvp");
      }
    }
    if (error) {
      clearTokens();
      navigate("/");
    }
  }, [resGroup]);

  useEffect(() => {
    if (error && error === "No data found for group id in auth token") {
      clearTokens();
      navigate("/");
    }
  }, [error]);

  return (
    <>
      {loading && (
        <Container className="h-100 d-flex align-items-center justify-content-center">
          <Loading />
        </Container>
      )}
      {!loading && group && (
        <>
          {/* <NavSideBar
            hasBeenClicked={hasBeenClicked}
            isOpen={isOpen}
            handleClose={() => setIsOpen(false)}
          />
          <CustomNavBar
            toggleNav={() => {
              setIsOpen(!isOpen);
              setHasBeenClicked(true);
            }}
          />
          <div
            style={{ padding: isMobile ? "1rem 0 2rem 0" : "4rem 0 2rem 0" }}
          ></div> */}
          <HomeNavWrapper>
            <Outlet />
          </HomeNavWrapper>
        </>
      )}
      {/* {error && (
        <Card>
          <Card.Header>We are sorry!</Card.Header>
          <Card.Body>
            There was an issue with the wesite. Please contact Angharad and
            Emily if this persists.
          </Card.Body>
        </Card>
      )} */}
    </>
  );
};

export default Public;
