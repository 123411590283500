interface IMenu {
  main: string[];
  starter: string[];
  dessert: string[];
}

export const Menu: IMenu = {
  starter: [
    "Smoked salmon, lemon, caper, rocket, black pepper, handmade bread. (g)",
    "Marinated Brie, apple slaw, handmade bread. (v) (g)",
  ],
  main: [
    "Chicken fillet, confit carrot, fondant potato, leek, bacon and sun blushed tomato cream. (g)",
    "Salmon fillet, pesto, rocket, roasted cherry tomatoes, balsamic potatoes. (g)",
    "Dietary requirements (please state below)",
  ],
  dessert: [
    "Blackberry and lime jam cheesecake, chantilly cream. (v)",
    "Sticky toffee pudding, butterscotch, vanilla ice cream. (v)",
  ],
};

export const ChildMenu: IMenu = {
  starter: ["Garlic bread"],
  main: ["Chicken goujons"],
  dessert: ["Ice ream"],
};
