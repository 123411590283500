import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { Attendee, Group } from "../../model";

// Define a type for the slice state
interface GroupState {
  groups: Group[] | null;
}

// Define the initial state using that type
const initialState: GroupState = {
  groups: null,
};

export const groupSlice = createSlice({
  name: "groups",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAdminGroups: (state, action: PayloadAction<Group[]>) => {
      state.groups = action.payload;
    },
    addGroup: (state, action: PayloadAction<Group>) => {
      if (state.groups) {
        state.groups.push(action.payload);
      } else {
        state.groups = [action.payload];
      }
    },
    updateGroup: (state, action: PayloadAction<Group>) => {
      if (state.groups) {
        const index = state.groups.findIndex(
          (group) => group.id === action.payload.id
        );
        state.groups[index] = action.payload;
      }
    },
    deleteGroup: (state, action: PayloadAction<string>) => {
      console.log("called delete with: ", action.payload);
      if (state.groups) {
        state.groups = state.groups.filter(
          (group) => group.id !== action.payload
        );
      }
    },
  },
});

export const { setAdminGroups, addGroup, updateGroup, deleteGroup } =
  groupSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getAdminGroups = (state: RootState) => state.admin.groups;

export default groupSlice.reducer;
