import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ExternalLink, Pause, Play, Trash } from "react-feather";
import {
  adminDeleteSong,
  InternalSongItem,
  useAdminGetSongs,
} from "../shared/client";
import { Loading } from "../shared/components/Simple";
import { SpotifySearch } from "../shared/components/Spotify";
import { useToastContext } from "../shared/components/Toast";

export const SongList = () => {
  const { error, loading, request, songs } = useAdminGetSongs();

  useEffect(() => {
    if ((!songs || error) && !loading) {
      request();
    }
  }, []);

  if (loading) return <Loading text="Fetching Songs" />;

  return (
    <Container>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Button className="my-4">Download Song details</Button>
        <SpotifySearch />
      </div>
      <Row className="my-4">
        <Col xs={12} md={6}>
          {songs?.slice(songs.length / 2)?.map((song, i) => (
            <SongAdminDetails
              refresh={request}
              song={song}
              key={`admin-song-${i}`}
            />
          ))}
        </Col>
        <Col xs={12} md={6}>
          {songs?.slice(0, songs.length / 2)?.map((song, i) => (
            <SongAdminDetails
              refresh={request}
              song={song}
              key={`admin-song-${i}`}
            />
          ))}
        </Col>
      </Row>
    </Container>
  );
};

const SongAdminDetails: React.FC<{
  song: InternalSongItem;
  key: any;
  refresh: () => void;
}> = ({ song, key, refresh }) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audioRef = useRef<any>();
  const toastContext = useToastContext();

  useEffect(() => {
    audioRef.current?.load();
  }, [key, song]);

  const playPreview = () => {
    if (!isPlaying) {
      setIsPlaying(true);
      audioRef.current?.play();
    } else {
      setIsPlaying(false);
      audioRef.current?.pause();
    }
  };

  const deleteSong = (id: string) => {
    const trackId = id.split("/").pop();
    adminDeleteSong(trackId!)
      .then(() => {
        toastContext?.addToast({ body: "Song deleted", variant: "success" });
        refresh();
      })
      .catch(() => {
        toastContext?.addToast({
          body: "Failed to delete song",
          variant: "danger",
        });
      });
  };

  return (
    <Row className="py-2" key={key}>
      <Col
        xs="auto"
        className="p-0 d-flex align-items-center"
        onClick={() => playPreview()}
      >
        <div style={{ position: "relative" }}>
          <img
            height="50px"
            width="50px"
            style={{ borderRadius: "5rem" }}
            src={song.img}
          ></img>
          {!isPlaying && (
            <Play
              size="20px"
              className="text-light"
              style={{
                position: "absolute",
                left: "32%",
                top: "30%",
                opacity: 0.75,
              }}
            />
          )}
          {isPlaying && (
            <Pause
              size="20px"
              className="text-light"
              style={{
                position: "absolute",
                left: "30%",
                top: "30%",
                opacity: 0.75,
              }}
            />
          )}
        </div>
      </Col>
      <Col className="d-flex align-items-center justify-content-between p-0">
        <Row className="w-100 align-items-center justify-content-between ps-3 m-0">
          <Col className="p-0">
            <small>{song.artist}</small>
            <br />
            <span>{song.title}</span>
          </Col>
          <Col xs="auto">
            <Trash
              className="text-danger"
              onClick={() => deleteSong(song.externalUrl)}
              size="20px"
            />
            <a href={song.externalUrl} target="_blank">
              <ExternalLink className="text-info mx-4" size="20px" />
            </a>

            <audio
              ref={(input) => {
                audioRef.current = input;
              }}
            >
              <source src={song.preview} type="audio/mp3" />
            </audio>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
