import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Pause, Play, PlusCircle } from "react-feather";
import { usePublicAddToPlaylist } from "../client";
import { SongItem, useSpotifySearchForSong } from "../spotifyClient";
import { Loading } from "./Simple";
import { useToastContext } from "./Toast";

export const SpotifySearch = () => {
  const { loading, error, result, request } = useSpotifySearchForSong();
  const [emptied, setEmptied] = useState<boolean>(true);
  const [shouldHide, setShouldHide] = useState<boolean>(true);
  const ref = useRef<any>();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShouldHide(true);
      } else {
        setShouldHide(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <Container
      style={{
        maxWidth: "350px",
        position: "relative",
        minHeight: result ? "500px" : "0px",
      }}
      ref={ref}
      className="p-0 m-0"
    >
      <Form.Group className="w-100">
        <Form.FloatingLabel label="Search for a song">
          <Form.Control
            className="w-100"
            type="text"
            placeholder="Search for a song"
            onChange={(e) => {
              const search = e.target.value;
              if (search.length > 3) {
                request(search);
                setEmptied(false);
              } else {
                setEmptied(true);
              }
            }}
          />
        </Form.FloatingLabel>
      </Form.Group>
      <div
        className="shadow px-4 bg-bg-light"
        style={{
          position: "absolute",
          top: "60px",
          zIndex: 1,
          width: "100%",
          //   maxWidth: "400px",
        }}
      >
        {loading && <Loading />}
        {error && error}
        {!shouldHide && result && !emptied && (
          <Container className="p-0">
            {result.map((song, i) => (
              <SongDetails song={song} key={`song-result-${i}`} />
            ))}
          </Container>
        )}
      </div>
    </Container>
  );
};

const SongDetails: React.FC<{ song: SongItem; key: any }> = ({ song, key }) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audioRef = useRef<any>();
  const { loading, request } = usePublicAddToPlaylist();
  const toastContext = useToastContext();

  useEffect(() => {
    audioRef.current?.load();
  }, [key, song]);

  const addSong = () => {
    if (!loading) {
      request(song)
        .then(() => {
          toastContext.addToast({
            variant: "success",
            body: "Song successfully added",
          });
        })
        .catch((err) => {
          toastContext.addToast({
            variant: "danger",
            body: `${JSON.stringify(
              err.response?.data?.error || "Unable to add song"
            )}`,
          });
        });
    }
  };

  const playPreview = () => {
    if (!isPlaying) {
      setIsPlaying(true);
      audioRef.current?.play();
    } else {
      setIsPlaying(false);
      audioRef.current?.pause();
    }
  };
  return (
    <Row className="py-2" key={key}>
      <Col
        xs="auto"
        className="p-0 d-flex align-items-center"
        onClick={() => {
          if (song.preview_url) {
            playPreview();
          }
        }}
      >
        <div style={{ position: "relative" }}>
          <img
            height="50px"
            width="50px"
            style={{ borderRadius: "5rem" }}
            src={song.album.images[0].url}
          ></img>
          {song.preview_url && (
            <>
              {!isPlaying && (
                <Play
                  size="20px"
                  className="text-light"
                  style={{
                    position: "absolute",
                    left: "32%",
                    top: "30%",
                    opacity: 0.75,
                  }}
                />
              )}
              {isPlaying && (
                <Pause
                  size="20px"
                  className="text-light"
                  style={{
                    position: "absolute",
                    left: "30%",
                    top: "30%",
                    opacity: 0.75,
                  }}
                />
              )}
            </>
          )}
        </div>
      </Col>
      <Col className="d-flex align-items-center justify-content-between p-0">
        <Row className="w-100 align-items-center justify-content-between ps-3 m-0">
          <Col className="p-0">
            <small>{song.artists[0].name}</small>
            <br />
            <span>{song.name}</span>
          </Col>
          <Col xs="auto" onClick={() => addSong()}>
            <PlusCircle className="text-primary" size="20px" />
            <audio
              ref={(input) => {
                audioRef.current = input;
              }}
            >
              <source src={song.preview_url} type="audio/mp3" />
            </audio>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
