import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { set, useForm } from "react-hook-form";
import { SubmitButton } from "../../shared/components/Simple";
import { adminSendEmailsToAccepted } from "../../shared/client";
import { useToastContext } from "../../shared/components/Toast";

interface CustomEmailModalProps {
  handleClose: () => void;
  show: boolean;
}

export const CustomEmailModal: React.FC<CustomEmailModalProps> = ({
  show,
  handleClose,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({});
  const toast = useToastContext();

  const onSubmit = (data: any) => {
    setLoading(true);
    adminSendEmailsToAccepted(
      data.subject,
      data.body.replace(/\n/g, "<br>"),
      data.testEmail === "" ? undefined : data.testEmail
    )
      .then(
        (res) => {
          setLoading(false);
          if (!data.testEmail) {
            resetForm();
            handleClose();
          }
          toast.addToast({
            body: res.data.result || "Emails sent successfully",
            variant: "success",
          });
        },
        (err) => {
          toast.addToast({
            body: err.data.result || "Could not send emails",
            variant: "danger",
          });
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const resetForm = () => {
    setValue("subject", "");
    setValue("body", "");
    setValue("testEmail", "");
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        resetForm();
      }}
      size="lg"
      scrollable
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Send Email to Accepted Guests</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3">
            <Form.Label aria-required={true}>Subject</Form.Label>
            <Form.Control
              type="text"
              placeholder="Subject"
              {...register("subject", { required: true })}
              isInvalid={!!errors.subject}
            />
            <Form.Control.Feedback type="invalid">
              required field
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Body</Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              {...register("body", { required: true })}
              isInvalid={!!errors.body}
            />
            <Form.Control.Feedback type="invalid">
              required field
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label aria-required={true}>Test Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Send a test email to an email address"
              {...register("testEmail")}
            />
          </Form.Group>
          <p className="mb-4">
            <small>
              This field will allow you to send an email to any address first to
              see how it looks. If a value is entered here it will only send to
              this email and not to any of the guests. If it is blank it will
              send to all accepted guests
            </small>
          </p>

          <div className="d-flex justify-content-end">
            <button
              className="btn btn-outline-primary me-2"
              onClick={() => {
                handleClose();
                resetForm();
              }}
            >
              Cancel
            </button>
            <div>
              <SubmitButton
                loading={loading}
                disabled={Object.entries(errors).length > 0}
              />
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
