import React, { useEffect } from "react";
import { Breakpoint, useResponsiveBreakpoint } from "../hooks/responsive";
import { Col, Row } from "react-bootstrap";

interface VerticalColsProps {
  array: JSX.Element[] | undefined;
}

const ColMapping: Record<Breakpoint, number> = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
};

const VerticalCols: React.FC<VerticalColsProps> = ({ array }) => {
  const breakpoint = useResponsiveBreakpoint();
  const [cols, setCols] = React.useState<Array<JSX.Element[]>>([]);

  useEffect(() => {
    if (array && breakpoint) {
      const newCols = [];
      for (let i = 0; i < array.length; i++) {
        if (!newCols[i % ColMapping[breakpoint]]) {
          newCols[i % ColMapping[breakpoint]] = [];
        }
        // @ts-ignore
        newCols[i % ColMapping[breakpoint]].push(array[i]);
      }
      setCols(newCols);
    }
  }, [breakpoint, array]);

  return (
    <Row>
      {cols.map((col, index) => (
        <Col xs={12} sm={6} md={4} lg={3} className="mb-4">
          {col}
        </Col>
      ))}
    </Row>
  );
};

export default VerticalCols;
