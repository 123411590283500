import React, { useEffect, useState } from "react";
import Gallery from "react-photo-gallery";

import photo1 from "../assets/personalPhotos/1.jpg";
import photo2 from "../assets/personalPhotos/2.jpg";
import photo3 from "../assets/personalPhotos/3.jpg";
import photo4 from "../assets/personalPhotos/4.jpg";
import photo5 from "../assets/personalPhotos/5.jpg";
import photo6 from "../assets/personalPhotos/6.jpg";
import photo7 from "../assets/personalPhotos/7.jpg";
import photo8 from "../assets/personalPhotos/8.jpg";
import photo9 from "../assets/personalPhotos/9.jpg";
import photo10 from "../assets/personalPhotos/10.jpg";
import photo11 from "../assets/personalPhotos/11.jpg";
import photo12 from "../assets/personalPhotos/12.jpg";
import photo13 from "../assets/personalPhotos/13.jpg";
import photo14 from "../assets/personalPhotos/14.jpg";
import photo15 from "../assets/personalPhotos/15.jpg";
import photo16 from "../assets/personalPhotos/16.jpg";
import photo17 from "../assets/personalPhotos/17.jpg";
import photo18 from "../assets/personalPhotos/18.jpg";
import photo19 from "../assets/personalPhotos/19.jpg";
import photo20 from "../assets/personalPhotos/20.jpg";
import { useIsMobile } from "../shared/hooks/responsive";
import { getNaturalImageDimensions } from "../shared/utils/images";

const photos = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
  photo10,
  photo11,
  photo13,
  photo14,
  photo15,
  photo16,
  photo17,
  photo18,
  photo19,
  photo20,
];

export const GalleryWedding: React.FC = () => {
  const [formattedPhotos, setFormattedPhotos] = useState<
    {
      src: string;
      width: number;
      height: number;
    }[]
  >([]);

  useEffect(() => {
    const determinePhotosSizes = async (urls: string[]) => {
      const photos = await Promise.all(
        urls.map(async (url) => {
          const dimensions = await getNaturalImageDimensions(url);
          return {
            src: url,
            width: dimensions.width,
            height: dimensions.height,
          };
        })
      );
      setFormattedPhotos(photos);
    };

    determinePhotosSizes(photos);
  }, []);

  const isMobile = useIsMobile();
  return (
    <>
      {formattedPhotos.length > 0 && (
        <Gallery
          photos={formattedPhotos}
          direction="column"
          columns={isMobile ? 2 : 3}
        />
      )}
    </>
  );
};
