import axios, { AxiosResponse } from "axios";
import { useState } from "react";
import { getTokens, setTokens } from "../admin/authentication";
import {
  getTokens as getPublicTokens,
  setTokens as setPublicTokens,
} from "../public/authentication";

import { Attendee, Group, RSVP } from "../model";
import { SongItem } from "./spotifyClient";
import { setGroup } from "./store/publicSlice";
import { useAppDispatch } from "./store/hooks";

const baseURL = process.env.REACT_APP_API_BASE_PATH;

const axiosAdminInstance = axios.create();

// Request interceptor for API calls
axiosAdminInstance.interceptors.request.use(
  async (config) => {
    // @ts-ignore
    config.headers = {
      Authorization: `Bearer ${getTokens().bearer}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosAdminInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      await axiosAdminInstance
        .post(`${baseURL}/auth/refresh/`, { refresh: getTokens().refresh })
        .then((res) => {
          setTokens({ token: res.data.access });
        })
        .catch(() => {
          window.location.href = `${
            process.env.REACT_APP_BASE_PATH !== "/"
              ? process.env.REACT_APP_BASE_PATH
              : ""
          }/admin/login`;
        });
      return axiosAdminInstance(originalRequest);
    }
    if (
      error.response.status === 500 &&
      error.response.data.error === "Not enough segments"
    ) {
      window.location.href = `${
        process.env.REACT_APP_BASE_PATH !== "/"
          ? process.env.REACT_APP_BASE_PATH
          : ""
      }/admin/login`;
    }
    return Promise.reject(error);
  }
);

const axiosPublicInstance = axios.create();

// Request interceptor for API calls
axiosPublicInstance.interceptors.request.use(
  async (config) => {
    // @ts-ignore
    config.headers = {
      Authorization: `Bearer ${getPublicTokens().bearer}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosPublicInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      await axiosPublicInstance
        .post(`${baseURL}/auth/refresh/`, {
          refresh: getPublicTokens().refresh,
        })
        .then((res) => {
          setPublicTokens({ token: res.data.access });
        })
        .catch(() => {
          window.location.href = `${
            process.env.REACT_APP_BASE_PATH !== "/"
              ? process.env.REACT_APP_BASE_PATH
              : ""
          }/auth`;
        });
      return axiosPublicInstance(originalRequest);
    }
    if (
      error.response.status === 500 &&
      error.response.data.error === "Not enough segments"
    ) {
      window.location.href = `${
        process.env.REACT_APP_BASE_PATH !== "/"
          ? process.env.REACT_APP_BASE_PATH
          : ""
      }/auth`;
    }
    return Promise.reject(error);
  }
);

export interface ServerResponse<T> {
  statusCode: number;
  error: any;
  result: T | null;
}

export const usePublicGetAttendee = (
  id: string | undefined
): {
  loading: boolean;
  attendee: Attendee | null | undefined;
  error: any;
  request: () => void;
} => {
  const [loading, setLoading] = useState<boolean>(false);
  const [attendee, setAttendee] = useState<Attendee | undefined | null>();
  const [error, setError] = useState<any>();

  const request = () => {
    setLoading(true);
    setError(null);
    axiosPublicInstance
      .get<ServerResponse<Attendee>>(`${baseURL}/attendee/public/${id}`)
      .then((res) => {
        setAttendee(res.data.result);
      })
      .catch((err) => {
        setError(parseError(err.response?.data?.error));
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return { loading, attendee, error, request };
};

export const publicRSVP = (
  id: string | undefined,
  attendee: Partial<Attendee>
): Promise<AxiosResponse<ServerResponse<Attendee>, any>> =>
  axiosPublicInstance.patch<ServerResponse<Attendee>>(
    `${baseURL}/attendee/public/${id}`,
    attendee
  );

export const useGetPublicAttendeeByName = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [attendee, setAttendee] = useState<Attendee | undefined | null>();
  const [error, setError] = useState<any>();

  const request = (details: { firstName: string; lastName: string }) => {
    setLoading(true);
    setError(null);
    axiosPublicInstance
      .post<ServerResponse<Attendee>>(`${baseURL}/attendee/public`, details)
      .then((res) => {
        setAttendee(res.data.result);
      })
      .catch((err) => {
        setError(parseError(err.response?.data?.error));
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return { loading, attendee, error, request };
};

export const usePublicAddToPlaylist = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const request = (song: SongItem) => {
    setLoading(true);
    const data = {
      preview: song.preview_url,
      img: song.album.images[0].url,
      externalUrl: song.external_urls.spotify,
      artist: song.artists[0].name,
      title: song.name,
    };
    return axiosPublicInstance
      .post<ServerResponse<string>>(`${baseURL}/song/public`, data)
      .then(() => {
        return true;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { loading, request };
};

export const resendDetails = (attendeeId: string) => {
  return axios
    .post(`${baseURL}/attendee/send-details`, { attendeeId })
    .then((res) => {})
    .catch((err) => {
      throw parseError(err.response.data.error);
    });
};

export const adminLogin = (details: { email: string; password: string }) => {
  return axios
    .post(`${baseURL}/auth/login`, details)
    .then((res) => {
      setTokens(res.data.result);
    })
    .catch((err) => {
      throw parseError(err.response.data.error);
    });
};

export const useAdminGetAtttendees = (): {
  loading: boolean;
  attendees: Attendee[] | null | undefined;
  error: any;
  request: () => void;
} => {
  const [loading, setLoading] = useState<boolean>(false);
  const [attendees, setAttendee] = useState<Attendee[] | undefined | null>();
  const [error, setError] = useState<any>();

  const request = () => {
    setLoading(true);
    axiosAdminInstance
      .get<ServerResponse<Attendee[]>>(`${baseURL}/attendee`)
      .then((res) => {
        setAttendee(res.data.result);
      })
      .catch((err) => {
        setError(parseError(err.response?.data?.error));
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return { loading, attendees, error, request };
};

export interface InternalSongItem {
  externalUrl: string;
  title: string;
  artist: string;
  preview: string;
  img: string;
}

export const useAdminGetSongs = (): {
  loading: boolean;
  songs: InternalSongItem[] | null | undefined;
  error: any;
  request: () => void;
} => {
  const [loading, setLoading] = useState<boolean>(false);
  const [songs, setSongs] = useState<InternalSongItem[] | undefined | null>();
  const [error, setError] = useState<any>();

  const request = () => {
    setLoading(true);
    axiosAdminInstance
      .get<ServerResponse<InternalSongItem[]>>(`${baseURL}/song`)
      .then((res) => {
        setSongs(res.data.result);
      })
      .catch((err) => {
        setError(parseError(err.response?.data?.error));
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return { loading, songs, error, request };
};

export const adminPostAttendee = (guest: {
  firstName: string;
  lastName: string;
  reservation?: { duration?: number };
}) => {
  return axiosAdminInstance.post<ServerResponse<Attendee>>(
    `${baseURL}/attendee`,
    { ...guest, response: RSVP.NOT_RESPONDED }
  );
};

export const adminDeleteAttendee = (
  groupId: string,
  id: string
): Promise<any> => {
  return axiosAdminInstance.delete<ServerResponse<any>>(
    `${baseURL}/attendee/${groupId}/${id}`
  );
};

export const useAdminPatchAttendee = (): {
  loading: boolean;
  attendee: Attendee | null | undefined;
  error: any;
  request: (a: Attendee) => void;
} => {
  const [loading, setLoading] = useState<boolean>(false);
  const [attendee, setAttendee] = useState<Attendee | undefined | null>();
  const [error, setError] = useState<any>();

  const request = (a: Attendee) => {
    const { id, ...idLessAttendee } = a;
    setLoading(true);
    setError(false);
    axiosAdminInstance
      .patch<ServerResponse<Attendee>>(
        `${baseURL}/attendee/${a.id}`,
        idLessAttendee
      )
      .then((res) => {
        setAttendee(res.data.result);
      })
      .catch((err) => {
        setError(parseError(err.response?.data?.error));
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return { loading, attendee, error, request };
};

export const adminDeleteSong = (id: string): Promise<any> =>
  axiosAdminInstance.delete<ServerResponse<any>>(`${baseURL}/song/${id}`);

const parseError = (err: any) => {
  if (typeof err === "object") {
    return JSON.stringify(err);
  }
  return err;
};

export const publicLogin = (details: { password: string }): Promise<Group> => {
  return axios
    .post(`${baseURL}/auth/public`, details)
    .then((res) => {
      setPublicTokens(res.data.result);
      return res.data.result.result;
    })
    .catch((err) => {
      throw parseError(err.response.data.error);
    });
};

export const usePublicGetDefaultGroup = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [group, setGroup] = useState<Group | undefined | null>();
  const [error, setError] = useState<any>();

  const request = () => {
    setLoading(true);
    return axiosPublicInstance
      .get<ServerResponse<Group>>(`${baseURL}/public/group`)
      .then((res) => {
        setGroup(res.data.result);
        return res.data.result;
      })
      .catch((err) => {
        setError(parseError(err.response?.data?.error));
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { loading, request, group, error };
};

export const useAdminCreateGroupAndAttendees = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [group, setGroup] = useState<Group | undefined | null>();
  const [error, setError] = useState<any>();

  const request = async (group: Group, attendees: Attendee[]) => {
    setLoading(true);
    setError(null);

    let tempGroup: Group;

    const groupId = await axiosAdminInstance
      .post<ServerResponse<Group>>(`${baseURL}/group`, {
        ...group,
        attendees: [],
      })
      .then((res) => {
        tempGroup = res.data.result!;
        return res.data.result!.id;
      })
      .catch((err) => {
        setError(parseError(err.response?.data?.error));
        console.log(err.response.data);
        setLoading(false);
      });

    if (groupId) {
      for (let attendee of attendees) {
        await axiosAdminInstance
          .post<ServerResponse<Attendee>>(
            `${baseURL}/attendee/${groupId}`,
            attendee
          )
          .then((res) => {
            tempGroup.attendeeDetails = tempGroup.attendeeDetails
              ? [...tempGroup.attendeeDetails, res.data.result!]
              : [res.data.result!];
            tempGroup.attendees = tempGroup.attendees
              ? [...tempGroup.attendees, res.data.result!.id]
              : [res.data.result!.id];
          })
          .catch((err) => {
            console.log("Error for: ", attendee, err);
            setError(
              `Could not create ${attendee.firstName} ${attendee.lastName}:` +
                parseError(err.response?.data?.error)
            );
          });
      }
    }
    // @ts-ignore
    if (tempGroup) {
      setGroup(tempGroup);
    }
    setLoading(false);
  };

  return { loading, request, group, error };
};

export const useAdminGetGroups = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [groups, setGroups] = useState<Group[] | undefined | null>();
  const [error, setError] = useState<any>();

  const request = () => {
    setLoading(true);
    return axiosAdminInstance
      .get<ServerResponse<Group[]>>(`${baseURL}/group`)
      .then((res) => {
        setGroups(res.data.result);
        return res.data.result;
      })
      .catch((err) => {
        setError(parseError(err.response?.data?.error));
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { loading, request, groups, error };
};

export const useAdminUpdateGroupAndAttendees = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [group, setGroup] = useState<Group | undefined | null>();
  const [error, setError] = useState<any>();

  const request = async (
    group: Group,
    attendees: Attendee[],
    groupId: string
  ) => {
    setLoading(true);
    setError(null);

    let tempGroup: Group;

    await axiosAdminInstance
      .patch<ServerResponse<Group>>(`${baseURL}/group/${groupId}`, {
        ...group,
      })
      .then((res) => {
        tempGroup = res.data.result!;
        return res.data.result!.id;
      })
      .catch((err) => {
        setError(parseError(err.response?.data?.error));
        console.log(err.response.data);
        setLoading(false);
      });

    const existingAttendees =
      attendees?.filter((a) => a.id !== undefined) || [];
    const newAttendees = attendees.filter((a) => a.id === undefined);

    for (let attendee of existingAttendees) {
      await axiosAdminInstance
        .patch<ServerResponse<Attendee>>(
          `${baseURL}/attendee/${groupId}/${attendee.id}`,
          attendee
        )
        .then((res) => {
          tempGroup.attendeeDetails = tempGroup.attendeeDetails
            ? [...tempGroup.attendeeDetails, res.data.result!]
            : [res.data.result!];
          tempGroup.attendees = tempGroup.attendees
            ? [...tempGroup.attendees, res.data.result!.id]
            : [res.data.result!.id];
        })
        .catch((err) => {
          console.log("Error for: ", attendee, err);
          setError(
            `Could not create ${attendee.firstName} ${attendee.lastName}:` +
              parseError(err.response?.data?.error)
          );
        });
    }

    for (let attendee of newAttendees) {
      await axiosAdminInstance
        .post<ServerResponse<Attendee>>(
          `${baseURL}/attendee/${groupId}`,
          attendee
        )
        .then((res) => {
          tempGroup.attendeeDetails = tempGroup.attendeeDetails
            ? [...tempGroup.attendeeDetails, res.data.result!]
            : [res.data.result!];
          tempGroup.attendees = tempGroup.attendees
            ? [...tempGroup.attendees, res.data.result!.id]
            : [res.data.result!.id];
        })
        .catch((err) => {
          console.log("Error for: ", attendee, err);
          setError(
            `Could not create ${attendee.firstName} ${attendee.lastName}:` +
              parseError(err.response?.data?.error)
          );
        });
    }
    // @ts-ignore
    if (tempGroup) {
      setGroup(tempGroup);
    }
    setLoading(false);
  };

  return { loading, request, group, error };
};

export const adminViewPassword = (groupId: string) => {
  return axiosAdminInstance.get<ServerResponse<string>>(
    `${baseURL}/showPassword/${groupId}`
  );
};

export const adminUpdatePassword = (groupId: string, password: string) => {
  return axiosAdminInstance.patch<ServerResponse<string>>(
    `${baseURL}/updatePassword/${groupId}`,
    {
      password,
    }
  );
};

export const adminSendEmailsToAccepted = (
  subject: string,
  body: string,
  testEmail: string | null | undefined
) => {
  return axiosAdminInstance.post<ServerResponse<string>>(`${baseURL}/email`, {
    subject,
    body,
    testEmail,
  });
};

export const adminDeleteGroup = (groupId: string) => {
  return axiosAdminInstance.delete<ServerResponse<string>>(
    `${baseURL}/group/${groupId}`
  );
};

export const usePublicPatchGroup = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const dispatch = useAppDispatch();

  const request = (group: Partial<Group>) => {
    setLoading(true);
    setError(null);
    axiosPublicInstance
      .patch<ServerResponse<Group>>(`${baseURL}/public/group`, group)
      .then((res) => {
        res.data.result && dispatch(setGroup(res.data.result));
      })
      .catch((err) => {
        setError(parseError(err.response?.data?.error));
        console.log(err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return { loading, error, request };
};

export const adminSendGroupInvite = (groupId: string) => {
  return axiosAdminInstance.get<ServerResponse<string>>(
    `${baseURL}/group/${groupId}/invite`
  );
};

export const adminSendAllInvite = (repsonseType?: RSVP) => {
  return axiosAdminInstance.post<ServerResponse<string>>(`${baseURL}/invite`, {
    repsonseType,
  });
};
