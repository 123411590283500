import React, { useEffect, useState } from "react";
import { AWS_S3_BUCKET, getBucket } from "../shared/utils/aws";
import Gallery from "react-photo-gallery";
import { useIsMobile } from "../shared/hooks/responsive";
import { getNaturalImageDimensions } from "../shared/utils/images";
import { useToastContext } from "../shared/components/Toast";
import { Spinner } from "react-bootstrap";
import { set } from "immer/dist/internal";

const S3Images: React.FC = () => {
  const isMobile = useIsMobile();
  const [photos, setPhotos] = useState<
    { src: string; width: number; height: number }[]
  >([]);
  const [loadingImages, setLoadingImages] = useState(false);
  const { addToast } = useToastContext();

  const determinePhotosSizes = async (urls: string[]) => {
    const photos = await Promise.all(
      urls.map(async (url) => {
        const dimensions = await getNaturalImageDimensions(url);
        return {
          src: url,
          width: dimensions.width,
          height: dimensions.height,
        };
      })
    );
    setPhotos(photos);
    setLoadingImages(false);
  };

  useEffect(() => {
    if (photos.length === 0) {
      setLoadingImages(true);
      getBucket().listObjectsV2(
        { Bucket: AWS_S3_BUCKET },
        function (err, data) {
          if (err) {
            addToast({
              body: "Error fetching images",
              variant: "danger",
            });
            console.log(err);
          } else {
            if (data.Contents) {
              const imageUrls = data.Contents.filter((content) => !!content.Key)
                .sort((a, b) =>
                  a.LastModified && b.LastModified
                    ? b.LastModified?.getTime() - a.LastModified?.getTime()
                    : 0
                )
                .map(
                  (content) =>
                    `https://${AWS_S3_BUCKET}.s3.${
                      process.env.REACT_APP_AWS_S3_REGION
                    }.amazonaws.com/${content.Key!}`
                );
              determinePhotosSizes(imageUrls);
            }
          }
        }
      );
    }
  }, [photos]);

  return (
    <>
      {loadingImages && (
        <div className="d-flex flex-column align-items-center justify-content-center my-4 py-4">
          <Spinner variant="primary" animation="border" />
          <p>Loading images</p>
        </div>
      )}
      {!loadingImages && photos.length > 0 && (
        <Gallery
          photos={photos}
          direction="column"
          columns={isMobile ? 2 : 3}
        />
      )}
    </>
  );
};

export default S3Images;
