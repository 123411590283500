import React, { useEffect, useState } from "react";
import { Button, Card, Container, Form, Row } from "react-bootstrap";
import { Group, RSVP } from "../model";
import { adminSendAllInvite, useAdminGetGroups } from "../shared/client";
import { Loading } from "../shared/components/Simple";
import { CreateGroup } from "./components/CreateGroupModal";
import { useAppDispatch } from "../shared/store/hooks";
import { getAdminGroups, setAdminGroups } from "../shared/store/adminSlice";
import { ResponsePill } from "../shared/components/GuestInfo";
import { useSelector } from "react-redux";
import VerticalCols from "../shared/components/VerticalCols";
import { EditGroup } from "./components/EditGroupModal";
import { useForm } from "react-hook-form";
import { useToastContext } from "../shared/components/Toast";
import { CSVLink } from "react-csv";
import { CustomEmailModal } from "./components/CustomEmailModal";

export const AttendeesTable = () => {
  const { groups: groupsRes, error, loading, request } = useAdminGetGroups();
  const [selectedGroup, setSelectedGroup] = useState<Group | undefined>();
  const [show, setShow] = useState<boolean>(false);
  const [showCreateGroup, setShowCreateGroup] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const groups = useSelector(getAdminGroups);
  const [search, setSearch] = useState<string>("");
  const handleClose = () => setShow(false);
  const { register, handleSubmit, reset, control, setValue } = useForm({});
  const { addToast } = useToastContext();
  const [isSendingAllInvites, setIsSendingAllInvites] =
    useState<boolean>(false);
  const [downloadRef, setDownloadRef] = useState<CSVLink>();
  const [showCustomEmailModal, setShowCustomEmailModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (!groups || groups.length === 0) {
      if ((!groupsRes || error) && !loading) {
        request();
      } else if (groupsRes) {
        dispatch(setAdminGroups(groupsRes));
      }
    }
  }, [groups, groupsRes]);

  if (loading) return <Loading text="Fetching Guests" />;

  const filteredGroups = () => {
    return groups?.filter((group) => {
      return (
        group.groupName.toLowerCase().includes(search.toLowerCase()) ||
        group.attendeeDetails.some(
          (attendee) =>
            attendee.firstName.toLowerCase().includes(search.toLowerCase()) ||
            attendee.lastName.toLowerCase().includes(search.toLowerCase())
        )
      );
    });
  };

  const sendAllInvites = (responseType?: RSVP) => {
    if (
      window.confirm("Are you sure you want to send invites to all guests?")
    ) {
      setIsSendingAllInvites(true);
      adminSendAllInvite(responseType)
        .then((res) => {
          addToast({
            body: "Successfully sent invites",
            variant: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          addToast({
            body: "Failed to send invites",
            variant: "danger",
          });
        })
        .then(() => {
          setIsSendingAllInvites(false);
        });
    }
  };

  const exportGuests = (responseType?: RSVP) => {
    const headers = [
      "firstName",
      "lastName",
      "email",
      "response",
      "guestType",
      "needsTransport",
      "starter",
      "main",
      "dessert",
      "dietaryRequirements",
    ];

    const csvData: any[] = [];

    groups?.forEach((group) => {
      group.attendeeDetails.forEach((attendee) => {
        if (
          !responseType ||
          (responseType && attendee.response === responseType)
        ) {
          const finalRow = [];

          finalRow.push(attendee.firstName);
          finalRow.push(attendee.lastName);
          finalRow.push(attendee.email || group.email);
          finalRow.push(attendee.response);
          finalRow.push(attendee.isChild ? "Child" : "Adult");
          finalRow.push(group.needsTransport ? group.needsTransport : "N/A");
          finalRow.push(attendee.starter);
          finalRow.push(attendee.main);
          finalRow.push(attendee.dessert);
          finalRow.push(attendee.dietaryRequirements);

          csvData.push(finalRow);
        }
      });
    });

    return [headers, ...csvData];
  };

  return (
    <Container>
      <Container>
        <p>Quick actions:</p>
        <div className="d-flex flex-wrap">
          <Button
            variant="outline-primary"
            className="me-2 my-1"
            onClick={() => sendAllInvites()}
            disabled={isSendingAllInvites}
          >
            Send all invites
          </Button>
          <Button
            variant="outline-primary"
            className="me-2 my-1"
            onClick={() => sendAllInvites(RSVP.NOT_RESPONDED)}
            disabled={isSendingAllInvites}
          >
            Send invites only to groups not responded
          </Button>
          {groups && groups.length > 0 && (
            <>
              <CSVLink data={exportGuests()} filename="Wedding List">
                <Button variant="outline-primary" className="me-2 my-1">
                  Export guest data
                </Button>
              </CSVLink>
              <CSVLink data={exportGuests(RSVP.YES)} filename="Wedding List">
                <Button variant="outline-primary" className="me-2 my-1">
                  Export only accepted guests
                </Button>
              </CSVLink>
            </>
          )}
          <Button
            variant="outline-primary"
            className="me-2 my-1"
            onClick={() => setShowCustomEmailModal(true)}
          >
            Send an Email to all accepted guests
          </Button>
        </div>
      </Container>
      <hr />
      <div className="d-flex">
        <Form.Control
          className="flex-grow-1"
          type="text"
          placeholder="Search by name or group name"
          {...register(`search`, {
            onChange: (e) => setSearch(e.target.value),
          })}
        />
        <Button
          variant="outline-primary"
          onClick={() => {
            setValue("search", "");
            setSearch("");
          }}
          className="ms-2"
        >
          Clear
        </Button>
      </div>

      <div className="d-flex my-4 justify-content-center">
        <Button onClick={() => setShowCreateGroup(true)}>Add Guests</Button>
      </div>
      <VerticalCols
        array={filteredGroups()?.map((group) => (
          <Card
            className="mb-4"
            onClick={() => {
              setSelectedGroup(group);
              setShow(true);
            }}
          >
            <Card.Header>{group.groupName}</Card.Header>
            <Card.Body>
              {group.attendeeDetails.map((attendee) => (
                <p>
                  <ResponsePill response={attendee.response}></ResponsePill>{" "}
                  {attendee.firstName} {attendee.lastName}
                </p>
              ))}
            </Card.Body>
          </Card>
        ))}
      />
      {selectedGroup && (
        <EditGroup
          handleClose={handleClose}
          group={selectedGroup}
          show={show}
        />
      )}
      <CreateGroup
        show={showCreateGroup}
        handleClose={() => setShowCreateGroup(false)}
      />
      <CustomEmailModal
        show={showCustomEmailModal}
        handleClose={() => setShowCustomEmailModal(false)}
      />
    </Container>
  );
};
