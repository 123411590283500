import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Toast, Button, ToastContainer } from "react-bootstrap";
import { AlertCircle, AlertTriangle, CheckCircle } from "react-feather";

interface ToastProps {
  variant?: "danger" | "warn" | "success";
  body?: string;
}

const ToastComponent: React.FC<ToastProps> = ({ variant, body }) => {
  return (
    <Toast>
      <Toast.Body>
        {variant === "success" && <CheckCircle className="text-success me-2" />}
        {variant === "danger" && <AlertCircle className="text-danger me-2" />}
        {body}
      </Toast.Body>
    </Toast>
  );
};

const ToastContext = createContext<{ addToast: (toast: ToastProps) => void }>({
  addToast: () => {},
});

export const ToastContextProvider: React.FC<any> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  const addToast = (toast: ToastProps) =>
    setToasts((toasts) => [...toasts, toast]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setToasts((toasts) => [...toasts].slice(1));
    }, 2000);
    return () => clearTimeout(timer)
  }, [toasts]);

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      {/* @ts-ignore */}

      <ToastContainer className="p-3" style={{position: 'fixed', top:0, right:0}}>
        {toasts.map((t, i) => (
          <ToastComponent {...t} />
        ))}
      </ToastContainer>
    </ToastContext.Provider>
  );
};

export const useToastContext = () => useContext(ToastContext);
