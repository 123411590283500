import { Badge, Button, Card, Col } from "react-bootstrap";
import { Attendee, RSVP } from "../../model";
import {
  Check,
  XCircle,
  HelpCircle,
  Mail,
  Info,
  FileText,
} from "react-feather";

export const ResponsePill: React.FC<{ response: RSVP }> = ({ response }) => {
  switch (response) {
    case RSVP.YES:
      return <Check className="text-success" size="20px" />;
    case RSVP.NO:
      return <XCircle className="text-danger" size="20px" />;
    default:
      return <HelpCircle className="text-info" size="20px" />;
  }
};

const GuestResponse: React.FC<{
  attendee: Attendee;
  onStartRSVP?: () => void;
  groupEmail?: string;
}> = ({ attendee, onStartRSVP, groupEmail }) => {
  return (
    <Col
      xs="10"
      sm="10"
      lg="6"
      xl="4"
      className="mb-4"
      style={{ maxWidth: "800px" }}
    >
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <Card.Title className="p-0 m-0 text-truncate">
            {attendee.firstName} {attendee.lastName}
          </Card.Title>
          <div style={{ minWidth: "20px" }}>
            <ResponsePill response={attendee.response}></ResponsePill>
          </div>
        </Card.Header>
        {attendee.response === RSVP.YES && (
          <Card.Body>
            {
              // <p>
              //   <Mail size="18px" className="pb-1" />{" "}
              //   {attendee.email || groupEmail}
              // </p>
            }
            {attendee.starter ? (
              <ul>
                <li>{attendee.starter}</li>
                <li>{attendee.main}</li>
                <li>{attendee.dessert}</li>
              </ul>
            ) : (
              "Guest has not yet selected a meal"
            )}
            {attendee.dietaryRequirements?.length > 0 && (
              <p>
                <Info size="18px" className="pb-1" />{" "}
                {attendee.dietaryRequirements}
              </p>
            )}
            {!attendee.dietaryRequirements && (
              <p>
                <Info size="18px" className="pb-1" /> No dietary requirements
              </p>
            )}
            {attendee.isChild && (
              <div className="d-flex justify-content-center mb-2">
                <Badge bg="primary" pill className="p-0 px-4 py-1 ">
                  Child
                </Badge>
              </div>
            )}
          </Card.Body>
        )}
        {attendee.response === RSVP.NOT_RESPONDED && (
          <Card.Body>
            {attendee.isChild && (
              <div className="d-flex justify-content-center mb-2">
                <Badge bg="secondary" pill className="p-0 px-4 py-1 ">
                  Child
                </Badge>
              </div>
            )}
            {attendee.response === RSVP.NOT_RESPONDED && (
              <div className="d-flex justify-content-center">
                <Button onClick={onStartRSVP}>Start RSVP</Button>
              </div>
            )}
          </Card.Body>
        )}
        {attendee.response === RSVP.NO && (
          <Card.Body>
            <p>Declined invitation</p>
          </Card.Body>
        )}
      </Card>
    </Col>
  );
};

export default GuestResponse;
