import React, { useEffect } from "react";
import ourStoryPhoto from "../assets/personalPhotos/nia-scott-personal.jpg";
import floralBorderSVG from "../assets/svg/floral-border.svg";

import { Container } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getTokens } from "./authentication";
import { useNavigationBoundaries } from "./utils";
import { useAppSelector } from "../shared/store/hooks";
import { getGroup } from "../shared/store/publicSlice";
import { Countdown } from "../shared/components/Countdown";
import { useIsMobile } from "../shared/hooks/responsive";

export const HomeNavWrapper: React.FC<any> = ({ children }) => {
  const isLoggedIn = getTokens()?.bearer;

  const location = useLocation();
  const group = useAppSelector(getGroup);
  const isMobile = useIsMobile();

  const { shouldNavigateNotResponded } = useNavigationBoundaries(group);
  const navigate = useNavigate();

  useEffect(() => {
    if (shouldNavigateNotResponded() && location.pathname !== "/rsvp") {
      navigate("/rsvp");
    }
  }, [location]);

  return (
    <Container fluid className="p-0">
      <Container className="p-0" fluid>
        <div
          className="mx-auto"
          style={{
            maxWidth: "1000px",
            position: "relative",
            backgroundImage: `url(${ourStoryPhoto})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: isMobile ? "400px" : "600px",
            backgroundColor: "rgba(0,0,0,0.2)",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0,0,0,0.13)",
            }}
            className="d-flex flex-column"
          >
            <div className="flex-grow-1 d-flex align-items-center justify-content-center flex-column ">
              <div
                className="text-light d-flex align-items-center justify-content-center flex-column ff-1 fz-3"
                style={{
                  backgroundImage: `url(${floralBorderSVG})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  fill: "white",
                  height: "250px",
                  width: "250px",
                }}
              >
                <span style={{ marginLeft: "-10px", marginTop: "12px" }}>
                  N & S
                </span>
              </div>
              <Countdown />
            </div>
            <div
              className="d-flex justify-content-center rounded-top"
              style={{
                backdropFilter: "blur(8px)",
                pointerEvents: isLoggedIn ? "all" : "none",
              }}
            >
              <NavLink
                className={({ isActive }) =>
                  `admin-nav ${isActive ? "nav-active" : ""}`
                }
                to={"/rsvp"}
              >
                RSVP
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  `admin-nav ${isActive ? "nav-active" : ""}`
                }
                to="/food"
              >
                Let's eat
              </NavLink>
              {/* <NavLink
                className={({ isActive }) =>
                  `admin-nav ${isActive ? "nav-active" : ""}`
                }
                to="/transport"
              >
                Transport
              </NavLink> */}
              <NavLink
                className={({ isActive }) =>
                  `admin-nav ${isActive ? "nav-active" : ""}`
                }
                to="/venue"
              >
                Location
              </NavLink>
            </div>
          </div>
        </div>
      </Container>
      <div style={{ maxWidth: "1440px" }} className="mx-auto">
        <div className="pb-4 bg-body mx-auto" style={{ maxWidth: "1200px" }}>
          {children}
        </div>
      </div>
    </Container>
  );
};
