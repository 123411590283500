import React, { useEffect, useRef, useState } from "react";
import { AWS_S3_BUCKET, getBucket } from "../utils/aws";
import { useToastContext } from "./Toast";
import { Button } from "react-bootstrap";

export const S3ImageUploader: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const { addToast } = useToastContext();
  const [files, setFiles] = useState([]);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleFileInput = (e: any) => {
    setSelectedFile(e.target.files[0]);
    setFiles(e.target.files);
    console.log(e.target.files);
  };

  const [uploadStatus, setUploadStatus] = useState<
    Record<
      string,
      { error: boolean; loading: boolean; success: boolean; progress: number }
    >
  >({});

  const updateUploadStatus = (
    fileName: string,
    status: {
      error: boolean;
      loading: boolean;
      success: boolean;
      progress: number;
    }
  ) => {
    setUploadStatus((prev) => ({
      ...prev,
      [fileName]: status,
    }));
  };

  const uploadFile = (file: any) => {
    updateUploadStatus(file.name, {
      error: false,
      loading: true,
      success: false,
      progress: 0,
    });

    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: AWS_S3_BUCKET,
      Key: file.name,
    };

    getBucket()
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        if (evt.loaded === evt.total) {
          updateUploadStatus(file.name, {
            error: false,
            loading: false,
            success: true,
            progress: 100,
          });
        } else {
          updateUploadStatus(file.name, {
            error: false,
            loading: true,
            success: false,
            progress: Math.round((evt.loaded / evt.total) * 100),
          });
        }
      })
      .send((err) => {
        if (err) {
          updateUploadStatus(file.name, {
            error: true,
            loading: false,
            success: false,
            progress: 0,
          });
          console.log(err);
        }
        if (err) console.log(err);
      });
  };

  useEffect(() => {
    if (Object.keys(files).length > 0) {
      Promise.all(
        Object.keys(files).map((idx: any) => {
          uploadFile(files[idx]);
        })
      ).then(() => {
        if (getFilesInLoadingStatus() === 0) {
          addToast({
            body: "Upload complete",
            variant: "success",
          });
        }
      });
    }
  }, [files]);

  const getFilesInLoadingStatus = () => {
    return Object.keys(uploadStatus).filter((key) => uploadStatus[key].loading)
      .length;
  };

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  return (
    <div>
      <Button className="button-upload" onClick={handleClick}>
        Upload a file
      </Button>
      <input
        type="file"
        accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG"
        ref={hiddenFileInput}
        style={{ display: "none" }}
        onChange={handleFileInput}
        multiple
      />
      {getFilesInLoadingStatus() > 0 && (
        <>
          Remaining {getFilesInLoadingStatus()} /{" "}
          {Object.keys(uploadStatus).length}
        </>
      )}
      {getFilesInLoadingStatus() === 0 && (
        <>All {Object.keys(uploadStatus).length} photos uploaded</>
      )}
    </div>
  );
};
