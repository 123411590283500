import { Container } from "react-bootstrap";
import { usePublicPatchGroup } from "../shared/client";
import { useAppSelector } from "../shared/store/hooks";
import { getGroup } from "../shared/store/publicSlice";

export const Transport = () => {
  const group = useAppSelector(getGroup);
  const { loading: patchGroupLoading, request: patchGroup } =
    usePublicPatchGroup();

  const updateTrasnportNeeds = (accepted: boolean) => {
    patchGroup({ id: group?.id, needsTransport: accepted });
  };
  return (
    <>
      {(group?.needsTransport === undefined ||
        group?.needsTransport === null) && (
        <Container className="my-4">
          <p className="text-center">
            Please let us know if you require transport to and from the venue.
          </p>
          <div className="d-flex justify-content-center">
            <button
              disabled={patchGroupLoading}
              className="btn btn-outline-primary"
              onClick={() => updateTrasnportNeeds(true)}
            >
              Yes
            </button>
            <button
              disabled={patchGroupLoading}
              className="ms-4 btn btn-outline-primary"
              onClick={() => updateTrasnportNeeds(false)}
            >
              No
            </button>
          </div>
        </Container>
      )}
      <Container className="my-4">
        <p className="text-center">
          {group?.needsTransport === true &&
            "You have requested transport to and from the venue."}
          {group?.needsTransport === false &&
            "You have have notified Nia and Scott you do not need transport."}
        </p>
      </Container>
    </>
  );
};
