import { useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { publicLogin } from "../shared/client";
import { RSVP } from "../model";
import { useAppDispatch } from "../shared/store/hooks";
import { setGroup } from "../shared/store/publicSlice";
import { BrandOne } from "../shared/demo/Brands";

const BEARER_TOKEN_KEY = "wedding_public_token";
const REFRESH_TOKEN_KEY = "wedding_public_refresh_token";

export const getTokens = (): {
  bearer: string | null;
  refresh: string | null;
} => {
  return {
    bearer: localStorage.getItem(BEARER_TOKEN_KEY),
    refresh: localStorage.getItem(REFRESH_TOKEN_KEY),
  };
};

export const setTokens = (tokens: { token?: string; refresh?: string }) => {
  tokens.refresh && localStorage.setItem(REFRESH_TOKEN_KEY, tokens.refresh);
  tokens.token && localStorage.setItem(BEARER_TOKEN_KEY, tokens.token);
};

export const clearTokens = () => {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(BEARER_TOKEN_KEY);
  window.location.pathname = "/auth";
};

export const Login = () => {
  const { handleSubmit, register } = useForm();
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSubmit = (data: any) => {
    setLoading(true);
    setError(undefined);
    publicLogin(data)
      .then((group) => {
        dispatch(setGroup(group));
        if (
          group.attendeeDetails.find(
            (a) => a.response === RSVP.NOT_RESPONDED
          ) ||
          group.attendeeDetails.filter((a) => a.response === RSVP.NO).length ===
            group.attendeeDetails.length
        ) {
          navigate("/rsvp");
        } else navigate("/rsvp");
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container fluid>
      <Container className="my-4">
        <p className="text-center">
          Welcome to our wedding website. You should have recieved a password
          via email to login
        </p>
      </Container>

      <Container style={{ maxWidth: "400px" }}>
        <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12}>
              <Form.Group className="mb-3">
                <Form.FloatingLabel label="Password">
                  <Form.Control
                    type="password"
                    placeholder="Passowrd"
                    {...register("password")}
                  />
                </Form.FloatingLabel>
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex flex-column justify-content-center">
            <Button type="submit" className="px-4">
              {loading ? (
                <Spinner variant="light" size="sm" animation="border" />
              ) : (
                "Sign In"
              )}
            </Button>
            {error && <p className="text-center text-danger mt-4">{error}</p>}
          </div>
        </Form>
      </Container>
    </Container>
  );
};
