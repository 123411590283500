import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { Attendee, Group, RSVP } from "../../model";

// Define a type for the slice state
interface GroupState {
  group: Group | null;
}

// Define the initial state using that type
const initialState: GroupState = {
  group: null,
};

export const groupSlice = createSlice({
  name: "group",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setGroup: (state, action: PayloadAction<Group>) => {
      state.group = {
        ...action.payload,
        attendeeDetails: action.payload.attendeeDetails.map((a) => ({
          ...a,
          response: a.response || RSVP.NOT_RESPONDED,
        })),
      };
    },
    updateAttendeeValues: (state, action: PayloadAction<Attendee>) => {
      state.group!.attendeeDetails = state.group!.attendeeDetails.map(
        (attendee) => {
          if (attendee.id === action.payload.id) {
            return action.payload;
          }
          return attendee;
        }
      );
    },
  },
});

export const { setGroup, updateAttendeeValues } = groupSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getGroup = (state: RootState) => state.public.group;
export const getAttendees = (state: RootState) =>
  state.public.group?.attendeeDetails;

export default groupSlice.reducer;
