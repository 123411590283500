import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { NavSideBar, NavBar as CustomNavBar } from "../public/navigation";
import { useIsMobile } from "../shared/hooks/responsive";

const Admin: React.FC<any> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const location = useLocation();
  const [hasBeenClicked, setHasBeenClicked] = React.useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    setIsOpen(false);
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <NavSideBar
        hasBeenClicked={hasBeenClicked}
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
      />
      <CustomNavBar
        toggleNav={() => {
          setIsOpen(!isOpen);
          setHasBeenClicked(true);
        }}
      />
      {!isMobile && <div style={{ padding: "4rem 0 2rem 0" }}></div>}
      <Outlet />
    </>
  );
};

export default Admin;
