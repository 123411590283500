import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { Attendee } from "../model";
import { publicRSVP } from "../shared/client";
import { useToastContext } from "../shared/components/Toast";
import { RSVP as Responses } from "../model";
import { useAppDispatch, useAppSelector } from "../shared/store/hooks";
import { getGroup, updateAttendeeValues } from "../shared/store/publicSlice";
import { NavLink } from "react-router-dom";
import { clearTokens } from "./authentication";

export const RSVPSimple: React.FC<{}> = ({}) => {
  const { addToast } = useToastContext();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const group = useAppSelector(getGroup);

  const respondForAttendee = (attendee: Attendee, answer: boolean) => {
    setLoading(true);

    publicRSVP(attendee.id, {
      response: answer ? Responses.YES : Responses.NO,
    })
      .then((res) => {
        dispatch(updateAttendeeValues(res.data.result!));
        addToast({
          body: "RSVP updated successfully",
          variant: "success",
        });
      })
      .catch((err: any) => {
        console.log(err.response?.data?.error);
        addToast({
          variant: "danger",
          body:
            JSON.stringify(err.response?.data?.error) ||
            "Unable to send response",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container fluid>
      <Row className="justify-content-center pb-4 mb-4">
        {group?.attendeeDetails.map((attendee) => {
          return (
            <Col
              key={attendee.id}
              xs="12"
              sm="10"
              lg="6"
              xl="4"
              className="my-4"
            >
              <Card>
                <Card.Header>
                  <p className="text-center fw-bold p-0 pb-2 m-0">
                    {attendee.firstName} {attendee.lastName}
                  </p>
                </Card.Header>
                <Card.Body>
                  {attendee.response === undefined ||
                  attendee.response === null ||
                  attendee.response === Responses.NOT_RESPONDED ? (
                    <div className="w-100">
                      <div className="d-flex flex-column">
                        <Button
                          onClick={() => respondForAttendee(attendee, true)}
                          variant="outline-primary"
                        >
                          {loading ? (
                            <Spinner
                              variant="light"
                              size="sm"
                              animation="border"
                            />
                          ) : (
                            "Yes, looking forward to it"
                          )}
                        </Button>
                        <Button
                          onClick={() => respondForAttendee(attendee, false)}
                          variant="outline-primary"
                          className="mt-4"
                        >
                          {loading ? (
                            <Spinner
                              variant="light"
                              size="sm"
                              animation="border"
                            />
                          ) : (
                            "Unable to attend"
                          )}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <>
                      {attendee.response === Responses.YES
                        ? "This person has already accepted the invitation"
                        : "This person has declined the invitation"}
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
      <p className="text-center">
        Please respond by <span className="fw-bold">28th June</span>!
      </p>
      <div className="d-flex justify-content-center">
        <NavLink to="/auth" className={""} onClick={clearTokens}>
          Logout
        </NavLink>
      </div>
    </Container>
  );
};
