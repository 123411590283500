import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

export const AWS_S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET!

export const getBucket = () => new AWS.S3({
  params: { Bucket: process.env.REACT_APP_AWS_S3_BUCKET },
  region: process.env.REACT_APP_AWS_S3_REGION,
});