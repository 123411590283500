import { Button, Card, Container } from "react-bootstrap";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import Public from "./public";
import { Home } from "./public/home";
import { AlreadyRespondedPage, RSVP } from "./public/rsvp";
import Admin from "./admin";
import { AttendeesTable } from "./admin/Attendees";
import { Login } from "./admin/authentication";
import { Login as PublicLogin, clearTokens } from "./public/authentication";

import { ToastContextProvider } from "./shared/components/Toast";
import { SongList } from "./admin/Songs";
import { Timeline, Venue } from "./public/Text";
import { SpotifySearch } from "./shared/components/Spotify";
import { MobileHome } from "./public/mobile-home";
import NotResponded from "./public/notResponded";
import { GalleryWedding } from "./public/Gallery";
import ImageUpload from "./no-auth/ImageUpload";
import { HomeNavWrapper } from "./public/HomeNavWrapper";
import { RSVPSimple } from "./public/rsvp-new";
import { FoodChoices } from "./public/food";
import { Transport } from "./public/transport";
import { VenueContainer } from "./public/Venue";

const ErrorPage = () => {
  const navigate = useNavigate();

  const logout = () => {
    clearTokens();
    navigate("/");
  };

  return (
    <Container>
      <Card className="mt-4">
        <Card.Header>
          <Card.Title>Something went wrong</Card.Title>
        </Card.Header>
        <Card.Body>
          <Card.Text>
            <p>
              We encountered an issue here. Normally this happens when data is
              incorrectly handled. Please log back in and try again.
            </p>

            <p>
              In the event of this happening constantly please reach out to Nia
              and Scott who will contact support to resolve this issue
            </p>
          </Card.Text>
          <div className="d-flex justify-content-center">
            <Button onClick={logout}>Logout</Button>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export const App = () => {
  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <Public />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "/",
            element: (
              <Container className="mt-4 p-2">
                <RSVPSimple />
              </Container>
            ),
          },
          // {
          //   path: "/agenda",
          //   element: (
          //     <Container className="">
          //       <Timeline />
          //     </Container>
          //   ),
          // },
          {
            path: "/rsvp",
            element: (
              <Container className="mt-4 p-2">
                <RSVPSimple />
              </Container>
            ),
          },
          {
            path: "/food",
            element: (
              <Container className="mt-4 p-2">
                <FoodChoices />
              </Container>
            ),
          },
          {
            path: "/transport",
            element: (
              <Container className="mt-4 p-2">
                <Transport />
              </Container>
            ),
          },
          {
            path: "/venue",
            element: (
              <Container className="mt-4 p-2">
                <VenueContainer />
              </Container>
            ),
          },
          // {
          //   path: "/gallery",
          //   element: (
          //     <Container>
          //       <GalleryWedding />
          //     </Container>
          //   ),
          // },
          // {
          //   path: "/song-request",
          //   element: (
          //     <Container className="mt-4 p-2 d-flex flex-column align-items-center justify-content-center">
          //       <p className="text-center" style={{ maxWidth: "800px" }}>
          //         We want our wedding to be inclusive and a celebration for all.
          //         Let’s make this night extra special by sharing in music we all
          //         love.
          //       </p>
          //       <SpotifySearch />
          //     </Container>
          //   ),
          // },
        ],
      },
      {
        path: "/error",
        element: <ErrorPage />,
      },

      {
        path: "/admin",
        element: <Admin />,
        // errorElement: <ErrorPage />,
        children: [
          {
            path: "/admin/",
            element: <AttendeesTable />,
          },
          {
            path: "/admin/login",
            element: <Login />,
          },
          {
            path: "/admin/create-account",
            element: <>create account</>,
          },
          {
            path: "/admin/guests",
            element: <AttendeesTable />,
          },
          {
            path: "/admin/guest/create",
            element: <>create guest</>,
          },
          {
            path: "/admin/home",
            element: <Home />,
          },
          {
            path: "/admin/rsvp",
            element: <RSVPSimple />,
          },
          {
            path: "/admin/rsvp/:id",
            element: <RSVP />,
          },
          {
            path: "/admin/songs",
            element: <SongList />,
          },
        ],
      },
      {
        path: "/response-required",
        element: (
          <HomeNavWrapper>
            <RSVPSimple />
          </HomeNavWrapper>
        ),
        errorElement: <ErrorPage />,
      },
      {
        path: "/auth",
        element: (
          <HomeNavWrapper>
            <PublicLogin />
          </HomeNavWrapper>
        ),
      },
      {
        path: "/image-share",
        element: <ImageUpload />,
      },
    ],
    { basename: process.env.REACT_APP_BASE_PATH }
  );

  window.scrollTo(0, 1);

  return (
    <>
      <ToastContextProvider>
        <Container
          fluid
          // style={{
          //   overflowX: "hidden",
          //   height: "100vh",
          // }}
          className="m-0 p-0"
        >
          <RouterProvider router={router} />
        </Container>
      </ToastContextProvider>
    </>
  );
};
