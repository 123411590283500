import React from "react";

import invite1Top from "../../assets/borders/invite-top-left.png";
import invite1Bottom from "../../assets/borders/invite-bottom-right.png";
import invite2Top from "../../assets/borders/invite-v2-top-left.png";
import invite2Bottom from "../../assets/borders/invite-v2-bottom-right.png";

export const Invite1: React.FC<any> = ({ children }) => {
  return (
    <div
      className="img-contain img-top-left"
      style={{ backgroundImage: `url(${invite1Top})` }}
    >
      <div
        className="img-contain img-bottom-right d-flex flex-column align-items-center justify-content-center"
        style={{ backgroundImage: `url(${invite1Bottom})` }}
      >
        {children}
      </div>
    </div>
  );
};


export const Invite2: React.FC<any> = ({ children }) => {
  return (
      <div
      className="img-contain img-top-left" 
      style={{ backgroundImage: `url(${invite2Top})` }}
    >
      <div
        className="img-contain img-bottom-right d-flex flex-column align-items-center justify-content-center"
        style={{ backgroundImage: `url(${invite2Bottom})` }}
      >
        {children}
      </div>
    </div>
  );
};

export const WhiteBorderCard: React.FC<any> = ({ children }) => (
  <div className="p-4 border-light">
    <div className="bg-white bg-opacity-90 px-4 pt-4 pb-2 m-2">{children}</div>
  </div>
);

export const Border1Top: React.FC<any> = ({ children, style }) => (
  <div
    className="img-contain img-top-left"
    style={{
      backgroundImage: `url(${invite1Top})`,
      height: "100%",
      width: "100%",
      ...style,
    }}
  />
);
