import { Button, Container, Spinner } from "react-bootstrap";

export const Loading = ({ text }: { text?: string }) => {
  return (
    <Container>
      <div className="d-flex my-4 justify-content-center align-items-center">
        <Spinner animation="grow" size="sm" variant="primary" />
        <Spinner
          animation="grow"
          size="sm"
          className="mx-1"
          variant="primary"
        />
        <Spinner animation="grow" size="sm" variant="primary" />
      </div>
      {!!text && (
        <div className="d-flex my-4 justify-content-center align-items-center">
          <small>{text}</small>
        </div>
      )}
    </Container>
  );
};

export const SubmitButton = ({
  text = "Submit",
  loading,
  disabled,
  variant = "primary",
  onclick,
  skipType = false,
}: {
  text?: string;
  loading?: boolean;
  disabled?: boolean;
  variant?: string;
  onclick?: (e: any | undefined) => void;
  skipType?: boolean;
}) => {
  return (
    <Button
      type={skipType ? "button" : "submit"}
      variant={variant}
      className="px-4 mx-auto"
      disabled={loading || disabled}
      onClick={(e) => {
        onclick && onclick(e);
      }}
    >
      {loading ? (
        <Spinner variant="light" size="sm" animation="border" />
      ) : (
        text
      )}
    </Button>
  );
};
