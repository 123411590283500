import { Col, Container, Row } from "react-bootstrap";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { Timeline } from "./Text";

export const VenueContainer: React.FC<any> = ({ children }) => {
  return (
    <Container>
      <Row>
        <Col md={6} sm={12}>
          <p className="ff-1 fz-3 text-center w-100 text-primary">Ceremony</p>
          <p className="text-center mt-4">
            Ceremony to start at 11am, please be seated by 10:45am.
          </p>

          <p className="text-center mt-4">
            Address: <br />
            <a href="https://maps.app.goo.gl/UneD1LC5oaB1nHMq6">
              Pembrey (St.Illtyd) Church, Gwscwm Road, Burry Port, SA16 0UA
            </a>
          </p>
          <CeremonyMapWrapper />
        </Col>
        <Col md={6} sm={12}>
          <p className="ff-1 fz-3 text-center w-100 text-primary">Party</p>
          <p className="text-center mt-4">
            Address: <br />
            <a href="https://maps.app.goo.gl/rUT3KiDDoZmYnEbf7">
              Mansion House, Pant Yr Athro, Llangain, Carmarthen, SA33 5AJ
            </a>
          </p>
          <PartyMapWrapper />
        </Col>
        {/* <Col xs={12} className="mt-4">
          <p className="ff-1 fz-3 text-center text-primary">Itinerary</p>
          <Timeline />
        </Col> */}
      </Row>
    </Container>
  );
};

const CeremonyMap = (props: any) => {
  return (
    // @ts-ignore
    <Map
      google={props.google}
      zoom={15}
      containerStyle={{
        minWidth: "100px",
        minHeight: "500px",
        position: "relative",
      }}
      mapTypeControl={false}
      fullscreenControl={false}
      streetViewControl={false}
      zoomControl={true}
      initialCenter={{ lat: 51.688022330633856, lng: -4.274997143795887 }}
    >
      {/* @ts-ignore */}
      <Marker position={{ lat: 51.688022330633856, lng: -4.274997143795887 }} />
    </Map>
  );
};

const CeremonyMapWrapper = GoogleApiWrapper({
  apiKey: "AIzaSyCnNJbGkW6yiETkR14dpTT3lJysUTEtThE",
})(CeremonyMap);

const PartyMap = (props: any) => {
  return (
    // @ts-ignore
    <Map
      google={props.google}
      zoom={15}
      containerStyle={{
        minWidth: "100px",
        minHeight: "500px",
        position: "relative",
      }}
      mapTypeControl={false}
      fullscreenControl={false}
      streetViewControl={false}
      zoomControl={true}
      initialCenter={{ lat: 51.795648468053635, lng: -4.367500001464067 }}
    >
      {/* @ts-ignore */}
      <Marker position={{ lat: 51.795648468053635, lng: -4.367500001464067 }} />
    </Map>
  );
};

const PartyMapWrapper = GoogleApiWrapper({
  apiKey: "AIzaSyCnNJbGkW6yiETkR14dpTT3lJysUTEtThE",
})(PartyMap);
